<template>
  <section class="features" id="features">
    <div class="container">
      <div class="row">
        <div class="col-12 checklists">
          <div class="row content-wrapper">
            <div class="col-12">
              <VueSlickCarousel v-bind="settings">
                <div class="items">
                  <div class="col-md-6 order-md-2 slider-wrapper">
                    <div class="image bk_1">
                      <img
                        src="../assets/img/features-checklist_img1@2x.png"
                        alt="Checklist"
                      />
                    </div>
                  </div>
                  <div class="col-md-6 order-md-1 feature-text">
                    <h3>Checklists personalizáveis</h3>
                    <p class="d-none d-md-block limit-text">
                      Memória infalível? Só com as nossas checklists.
                    </p>
                    <p class="d-md-none">
                      Memória infalível?<br />
                      Só com as nossas checklists.
                    </p>
                    <p>
                      Personaliza-as e garante que nada fica para trás. Para te
                      dar uma ajuda, nós acrescentamos alguns itens a pensar no
                      teu destino.<br />
                      <br />
                      Se viajares acompanhado, podes convidar os teus amigos ou
                      familiares e todos terão acesso a uma checklist
                      partilhada, para que ninguém se esqueça de nada.
                    </p>
                  </div>
                </div>
                <div class="items">
                  <div class="col-md-6 order-md-2 slider-wrapper">
                    <div class="image bk_2">
                      <img
                        src="../assets/img/features-tips@2x_2.png"
                        alt="Dicas"
                      />
                    </div>
                  </div>
                  <div class="col-md-6 order-md-1 feature-text">
                    <h3>Dicas para uma viagem mais smooth</h3>
                    <p>
                      Não precisas de perder horas à procura das melhores dicas
                      para a tua viagem.
                      <br /><br />
                      Just in Case diz-te tudo o que precisas de saber.
                    </p>
                  </div>
                </div>
                <div class="items">
                  <div class="col-md-6 order-md-2 slider-wrapper">
                    <div class="image bk_3">
                      <img
                        src="../assets/img/features-support-complete@2x.png"
                        alt="Apoio ao viajante"
                      />
                    </div>
                  </div>
                  <div class="col-md-6 order-md-1 feature-text">
                    <h3>Apoio gratuito ao viajante 24/7</h3>
                    <p>
                      Através do apoio gratuito ao viajante podes obter
                      informações e recomendações para qualquer destino do
                      mundo.
                    </p>
                    <p class="bullet">
                      Doenças locais, vacinas recomendadas ou obrigatórias e
                      medicamentos a levar
                    </p>
                    <p class="bullet">
                      Hospitais, aeroportos, consulados e embaixadas
                    </p>
                    <p class="bullet">
                      Principais pontos de interesse turístico
                    </p>
                    <p class="bullet">Informação meteorológica</p>
                    <p class="asterisk marginT">
                      Ao contratares o seguro de viagem tens também assistência
                      em caso de doença ou acidente.
                    </p>
                  </div>
                </div>
                <div class="items">
                  <div class="col-md-6 order-md-2 slider-wrapper">
                    <div class="image bk_4">
                      <img
                        src="../assets/img/features-insurance@2x_4.png"
                        alt="on-demand"
                      />
                    </div>
                  </div>
                  <div class="col-md-6 order-md-1 feature-text">
                    <h3>Seguro de viagem on-demand</h3>
                    <p>
                      Subscreve um seguro de viagem para ti e para a tua
                      família, e viaja em segurança com a nossa assistência
                      disponível 24 horas por dia, 7 dias por semana.
                      <br /><br />
                      Tudo na app, sem complicações.
                    </p>
                  </div>
                </div>
              </VueSlickCarousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "Features",
  components: { VueSlickCarousel },
  data() {
    return {
      settings: {
        arrows: false,
        dots: true,
        dotsClass: "slick-dots custom-dot-class",
        edgeFriction: 0.35,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplaySpeed: 5000,
        autoplay: true,
        infinite: true,
        pauseOnDotsHover: true,
        //pauseOnFocus: true,
        //pauseOnDotsHover: true,
      },
    };
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/_variables.scss";
// @import '@/assets/sass/_media.scss';

.features {
  margin: 50px 0px;
  @media (min-width: $medium) {
    margin: 100px 0px;
  }

  @media (min-width: $xlarge) {
    /*.slider-wrapper {
			padding-right: 80px;
		}*/
    .feature-text {
      padding-left: 80px;
    }
  }

  @media (min-width: 1280px) {
    /*.slider-wrapper {
			padding-right: 150px;
		}*/
    .feature-text {
      padding-left: 150px;
    }
  }
  .slick-slider {
    height: 100%;

    @media (min-width: $medium) {
      //max-width: 343px;
    }

    button {
      z-index: 10;

      &.slick-prev {
        left: 0;
      }

      &.slick-next {
        right: 0;
      }
    }
    @media (max-width: $medium) {
      .slick-slide {
        height: 0px;

        &.slick-current {
          height: 100%;
        }
      }
    }
  }

  .slick-dots {
    position: absolute;
    top: 310px;
    height: 30px;

    @media screen and (min-width: $medium) {
      position: absolute;
      bottom: unset;
      top: 100%;
      //width: 200px;
      //left: 100%;
      //transform: translateY(-50%);
      //top: 50%;
      //left: calc(100%);
      //margin: 0px;
    }
    /*
    @media (min-width: $medium) and (max-width: $xlarge) {
      left: calc(100%);
    }
    /*
    @media (min-width: $xlarge) {
      left: calc(89%);
    } */

    :last-child {
      margin: 0;
    }

    :first-child {
      margin: 0 15px 0 0;

      @media (min-width: $medium) {
        margin: 0 20px 0 0;
      }
    }

    li {
      width: 10px;
      height: 10px;
      margin: 0 15px 0 0;

      @media (min-width: $medium) {
        width: 16px;
        margin: 0 20px 0 0;
      }

      &.slick-active {
        button {
          background-color: $color-main;
        }
      }

      button {
        border-radius: 10px;
        background-color: $color-gray-button-slider;
        width: 10px;
        height: 10px;

        @media (min-width: $medium) {
          width: 16px;
          height: 16px;
        }

        &::before {
          width: 10px;
          height: 10px;
          color: transparent;

          @media (min-width: $medium) {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }

  .content-wrapper {
    color: $color-gray-features;
    margin-top: 20px;

    .items {
      display: block;

      @media (min-width: $medium) {
        display: flex !important;
      }
      h3 {
        font-size: 20px;
        line-height: 32px;
        letter-spacing: 0.23px;
        margin-bottom: 20px;
        font-weight: bold;

        // Exception
        @media (min-width: 340px) {
          font-size: 22px;
        }

        @media (min-width: $medium) {
          font-size: 36px;
          margin-bottom: 40px;
          line-height: 1.33;
          letter-spacing: 0.2px;
        }
      }

      p.bullet {
        position: relative;
        margin-bottom: 40px;
        @media (min-width: $medium) {
          margin-bottom: 16px;
        }
        &::before {
          content: "";
          background-image: url("../assets/img/bullet.png");
          width: 20px;
          height: 14px;
          display: inline-block;
          position: absolute;
          top: -20px;
          left: 50%;
          transform: translateX(-50%);

          @media (min-width: $medium) {
            position: relative;
            margin-right: 10px;
            top: 2px;
            left: 0%;
            transform: translateX(0);
          }
        }
      }

      p {
        font-weight: 200;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.16px;
        margin-bottom: 30px;
        padding-bottom: 0;

        @media (min-width: $medium) {
          font-size: 20px;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.17px;
          color: #8b8b8b;
          font-weight: 300;
        }

        &.asterisk {
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.18px;
          @media (min-width: $medium) {
            &.marginT {
              margin-top: 40px;
            }
          }
        }
      }
      .feature-text {
        @media (min-width: $medium) {
          //max-width: 540px;
          margin: 0 auto;
        }
      }
    }
  }
  .checklists {
    text-align: center;

    @media (min-width: $medium) {
      text-align: left;
    }

    .slider-wrapper {
      height: 300px;
      padding-bottom: 0;
      margin-bottom: 60px;
      //margin-right: auto;
      //margin-left: auto;
      .image {
        height: 100%;
        //margin: 0 auto;

        &.bk_1 {
          /* background-image: url('../assets/img/features-checklist_bg@2x_1.png');
					background-repeat: no-repeat;
					background-size: contain;
					width: 343px; */
          margin: 0 auto;
          @media screen and (min-width: $medium) and (max-width: $large) {
            height: 330px;
            max-width: 444px;
          }
          @media (min-width: $large) {
            margin-left: 0;
            /* height: 478px; */
          }

          img {
            @media (min-width: $medium) {
              margin-left: 0;
            }
          }
        }

        &.bk_2 {
          background-image: url("../assets/img/features-tips_bg@2x_2.png");
          background-repeat: no-repeat;
          background-size: cover;
          @media screen and (min-width: $medium) and (max-width: $large) {
            width: 361px;
            height: 360px;
            max-width: 444px;
          }
          @media (min-width: $large) {
            width: 479px;
            height: 460px;
          }
        }

        &.bk_3 {
          /*background-image: url('../assets/img/features-tips_bg@2x_2.png');
					background-repeat: no-repeat;
					background-size: cover;*/
          margin: 0 auto;
          @media screen and (min-width: $medium) and (max-width: $large) {
            height: 360px;
            max-width: 444px;
          }
          @media (min-width: $large) {
            margin-left: 0;
          }

          img {
            @media (min-width: $medium) {
              margin-left: 0;
            }
          }
        }

        &.bk_4 {
          background-image: url("../assets/img/lounge_bg@2x_4.png");
          background-repeat: no-repeat;
          background-size: 62%;
          background-position-x: 50%;
          background-position-y: center;
          @media screen and (min-width: $medium) and (max-width: $large) {
            width: 361px;
            height: 360px;
            max-width: 444px;
            background-size: cover;
            background-position-x: 17px;
          }
          @media (min-width: $large) {
            width: 479px;
            height: 478px;
            background-size: cover;
            background-position-x: 17px;
          }
        }

        img {
          height: 100%;
          margin: 0 auto;
        }
      }

      @media (min-width: $medium) {
        height: 450px;
      }
    }
  }
}
</style>