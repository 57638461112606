import Vue from 'vue'
import VueRouter from 'vue-router'
import VueWaypoint from 'vue-waypoint'
import VueCollapse from 'vue2-collapse'
import PrettyCheckbox from 'pretty-checkbox-vue';
import VTooltip from 'v-tooltip'
import App from './App.vue'
import '@/styles.scss';

// Set the different layouts for the project
// - Default: is the one used for the landing page
// - Clean: is used only to show the simulation results
import Default from "./layouts/Default.vue";
import Clean from "./layouts/Clean.vue";

// Waypoint plugin
Vue.use(VueWaypoint)
// Router plugin
Vue.use(VueRouter)
// Accordion plugin
Vue.use(VueCollapse)
// Radio buttons and checkboxes styling plugin
Vue.use(PrettyCheckbox)
// Tooltips plugin
Vue.use(VTooltip)

// Simple Router just to be able to access this.$route inside components
// and to set the proper layouts
let router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'justincase',
      meta: { layout: 'default' },
      component: App,
    },
    {
      path: '/detalhe-simulacao/',
      name: 'detalhe',
      meta: { layout: 'clean' },
      component: Default,
    },
  ],
  scrollBehavior() {
    document.getElementById('app').scrollIntoView();
  }
})

Vue.component('default-layout', Default);
Vue.component('clean-layout', Clean);

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
