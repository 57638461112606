<template>
	<div class="height-div" ref="heightDiv">
		<v-collapse-wrapper v-on:beforeToggle="toggleAccordionAction(index)" v-for="(item, index) in accordionContentValues" :key="item.title">
			<div :class="['accordion', { ['active d-flex justify-content-between']: index === activeItem }]" v-collapse-toggle>
				<span>{{ item.title }}</span>
				<div class="arrow-close-container">
					<div class="arrow-close"></div>
				</div>
			</div>
			<div class="accordion-content" v-collapse-content>
				<span ref="contentBox" v-html="item.content"></span>
			</div>
		</v-collapse-wrapper>
	</div>
</template>

<script>
export default {
	name: 'Accordion',
	components: {},
	props: {
		accordionContent: Array
	},
	created() {
		window.addEventListener('resize', this.handleResize);
		this.handleResize();
	},
	destroyed() {
		window.removeEventListener('resize', this.handleResize);
	},
	methods: {
		toggleAccordionAction(index) {
			this.currentItem = index;
			if(this.activeItem === this.currentItem) {
				this.activeItem = null;
				this.$refs.heightDiv.removeAttribute('style');
			}
			else {
				this.activeItem = this.currentItem;
			}

			// Only check the height of the accordion content area if
			// we are in an higher resolution and if we're not closing
			// the accordion
			if(this.window.width >= 768 && this.activeItem !== null) {
				// Set the height of the div to open the accordion correctly
				let heightSpan = this.$refs.contentBox[index].clientHeight;
				let tabContentHeight = document.getElementById("tab-content").offsetHeight;
				let conditionalHeight = 0;
				if(parseInt(heightSpan) > parseInt(tabContentHeight)) {
					conditionalHeight = parseInt(heightSpan);
				}
				else {
					conditionalHeight = parseInt(tabContentHeight);
				}
				this.$refs.heightDiv.setAttribute('style','height: ' + conditionalHeight + 'px');
			}
		},
		handleResize() {
			this.window.width = window.innerWidth;
			this.window.height = window.innerHeight;
		}
	},
	data() {
		return {
			accordionContentValues: this.accordionContent,
			activeItem: null,
			currentItem: -1,
			window: {
				width: 0,
				height: 0
			},
		}
	}
}
</script>

<style lang="scss" >
@import '@/assets/sass/_variables.scss';

/* .height-div {
	min-height: 300px;
} */

.vc-collapse {
	&:not(:last-child) {
		border-bottom: 1px solid $color-black-transp;
	}

	@media (min-width: $medium) {
		display: flex;
		width: 50%;
		max-width: 445px;
	}
}

.content {
	position: relative;
}

.accordion {
	padding: 16px 0;
	text-align: left;

	@media (min-width: $medium) {
		width: 100%;
		cursor: pointer;
	}
	
	span {
		color: $color-black;
		opacity: 0.4;
		font-size: 14px;
		font-weight: 600;
		line-height: 20px;
		letter-spacing: 0.12px;

		@media (min-width: $medium) {
			font-size: 18px;
		}
	}

	.arrow-close-container {
		display: none;
		min-width: 20px;
		max-width: 20px;
		height: 20px;
		border-radius: 10px;
		background-color: $color-main;
		margin-top: 4px;

		@media (min-width: $medium) {
			transform: rotate(90deg);
			margin: auto 0px;
		}
		
		.arrow-close {
			border: solid $color-white;
			border-width: 0 1px 1px 0;
			display: inline-block;
			padding: 3px;
			transform: rotate(225deg);
			-webkit-transform: rotate(225deg);
			margin: 8px 0 0 6.5px;
		}

	}

	&.active {
		span {
			opacity: 1;
			width: 90%;
		}

		.arrow-close-container {
			display: block;
		}
	}
}

.accordion-content {
	height: 0px;
	max-height: 0;
	overflow: hidden;
	transition: 0.1s ease all;
	text-align: left;

	@media (min-width: $medium){
		position: absolute;
		left: 50%;
		top: 16px;
		margin-left: 60px;
	}

	@media (min-width: 1280px){
		margin-left: 40px;
	}

	@media (min-width: $xxlarge){
		margin-left: 0px;
	}

	&.v-collapse-content-end {
		padding: 0;
		height: 100%;
		max-height: 1000px;
		overflow: hidden;
		transition: 0.3s ease all;
	}

	span {
		display: block;
		color: $color-gray-features;
		font-size: 16px;
		font-weight: 200;
		line-height: 24px;
		letter-spacing: 0.15px;
		padding-bottom: 15px;

		@media (min-width: $medium) {
			font-size: 18px;
			line-height: 26px;
			letter-spacing: 0.16px;
		}

		@media (min-width: $large) {
			font-size: 20px;
			line-height: 30px;
			letter-spacing: 0.17px;
		}
	}
}
</style>