<template>
	<transition name="modal">
		<div class="modal-mask">
			<div class="modal-wrapper">
				<div class="modal-container">

					<div class="modal-header-custom">
						<a @click="$emit('close')">
							<i slot="extra" class="icon mdi mdi-close"></i>
						</a>
					</div>

					<div class="modal-body-custom">
						<slot name="body">
							<div class="modal-body-title text-center">
								<span>Introduz o teu número e recebe o link para fazeres o download da app:</span>
							</div>
							<div class="modal-body-input mx-auto">
								<input type="text" name="phoneNumber" ref="phoneNumberInput" id="phoneNumber" :value="phoneNumber" v-on:input="changeInputText" maxlength="16" @keypress="preventLetterInput" @keydown="preventDeleteKey" placeholder="O teu número de telefone">
							</div>
							<div :class="['modal-body-button mx-auto', activateConfirmButton ? 'active' : null ]"><span>Confirmar</span></div>
						</slot>
					</div>

				</div>
			</div>
		</div>
	</transition>
</template>

<script>
let phoneNumberBuildString = '';
let phoneNumberCounter = 0;

export default {
	name: 'Modal',
	data() {
		return {
			phoneNumber: '',
			prefix: '+351 ',
			activateConfirmButton: false
		}
	},
	methods: {
		changeInputText: function($event) {
			// If the user types a key other than backspace or delete
			if($event.data !== null) {
				// We must check the lenght of the string to add an extra space
				// after the 8th and 12th number in the string (don't forget we're 
				// always using +351 as a prefix)
				if(phoneNumberCounter === 8 || phoneNumberCounter === 12) {
					phoneNumberBuildString += ' ';
				}
				// Add the number to the auxiliary string that has the complete
				// phonenumber stored (with the '+351 ' string)
				phoneNumberBuildString += $event.data;
			}
			// If the user types backspace or delete keys
			else {
				// We set the auxiliary string with the current value in the input
				// without the '+351 'string
				phoneNumberBuildString = (this.$refs.phoneNumberInput.value).slice(5);
			}
			// Build the complete string '+351 ' + the number typed by the user
			// The variable phoneNumber is set so the VueJS v-model can have the correct
			// number to use in the input value
			this.phoneNumber = this.prefix + phoneNumberBuildString;
			// Stores the length of the phoneNumber variable so it can bue used
			// in the validation where we add the ' ' to the complete string
			phoneNumberCounter = this.phoneNumber.length;
			// Set the active class in the Confirm button
			if(phoneNumberCounter === 16) { this.activateConfirmButton = true; }
			else { this.activateConfirmButton = false; }
		},
		// Prevents the letter input, only accepting numbers to be typed in
		// the input text element
		preventLetterInput: function($event) {
			let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
			// Only keycodes between these two values are allowed (numbers only)
			// Also remove the Delete code (46)
			if (keyCode < 47 || keyCode > 58) {
				// Prevent the default behavior
				$event.preventDefault();
			}
		},
		// Prevents the delete key input to avoid malformed numbers
		// in the input text. We are always writing +351 and setting
		// the value of the text input programmatically, so the carret
		// will always move to the end of the input
		preventDeleteKey: function($event) {
			let keyCode = $event.which || $event.keyCode || $event.charCode;
			if(keyCode === 46) {
				// Prevent the default behavior
				$event.preventDefault();
			}
		}
	}
}
</script>

<style lang="scss">
@import '@/assets/sass/_variables.scss';

.modal-mask {
	position: fixed;
	z-index: 9998;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: table;
	transition: opacity 0.3s ease;
	color: $color-black;
}

.modal-wrapper {
	display: table-cell;
	vertical-align: middle;
}

.modal-container {
	width: 327px;
	margin: 0px auto;
	padding: 20px 20px;
	background-color: #fff;
	border-radius: 10px;
	transition: all 0.3s ease;

	@media (min-width: $medium) {
		width: 730px;
	}

	.modal-header-custom {
		position: relative;
		padding: 0;
		border: 0;
		margin-top: 0;

		a {
			display: block;
			width: 24px;
			height: 24px;
			margin-left: auto;
			font-size: 24px;
			line-height: 24px;
			cursor: pointer;

			i {
				color: $color-gray;
			}
		}
	}

	.modal-body-custom {

		.modal-body-title {
			margin-top: 20px;

			@media (min-width: $medium) {
				margin-left: 160px;
				margin-right: 160px;
			}

			span {
				color: $color-black;
				font-size: 16px;
				font-weight: 600;
				line-height: 20px;
				letter-spacing: 0.13px;

				@media (min-width: $medium) {
					font-size: 20px;
					font-weight: bold;
					line-height: normal;
				}
			}
		}

		.modal-body-input {
			margin: 30px 0;
			width: 220px;
			border-bottom: 2px solid $color-gray-radios;

			@media (min-width: $medium) {
				width: 270px;
			}

			input {
				width: 100%;
				border: 0;
				height: 35px;
				text-align: center;
				color: $color-black;

				@media (min-width: $medium) {
					font-size: 18px;
					padding-bottom: 10px;
				}
			}
		}

		.modal-body-button {
			width: 80%;
			height: 40px;
			border-radius: 20px;
			color: $color-white;
			text-align: center;
			background-color: $color-blue-button;
			opacity: 0.5;
			margin-bottom: 50px;

			@media (min-width: $medium) {
				width: 230px;
			}

			span {
				font-size: 16px;
				line-height: 40px;
			}

			&.active {
				opacity: 1;
			}
		}

		.input-tel__label {
			@media (min-width: $medium) {
				top: -1px;
				font-size: 11px!important;
			}
		}
	}
}


/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

</style>