<template>
	<div id="app">
		<component :is="layout">
			<router-view />
		</component>
	</div>
</template>

<script>
const defaultLayout = 'default';

export default {
	name: 'App',
	data() {
		return {

		}
	},
	computed: {
		layout() {
			return (this.$route.meta.layout || defaultLayout) + '-layout';
		}
	},
	watch: {
        $route: {
            immediate: true,
            handler(to, from) {
                document.title = to.meta.title || 'Just in Case - Insured by Fidelidade';
            }
        },
    }
}
</script>

<style lang="scss">
@import '@/assets/sass/_variables.scss';
@import '../node_modules/bootstrap/scss/bootstrap.scss';

#app {
	min-height: 100%;
}
</style>