<template>
	<section class="footer">
		<div class="container">
			<div class="row">
				<div class="col-12 content-wrapper text-center">
					<h3>Faz o download agora!</h3>
					<div class="apps-panel d-flex justify-content-center">
						<a v-on:click="grplay" v-show="isAndroid" href="https://play.google.com/store/apps/details?id=pt.fidelidade.b2c.JustinCase&hl=pt_PT"><img src="../assets/img/play-store@2x.png" alt=""></a>
						<a v-on:click="arstore" v-show="isIos" href="https://apps.apple.com/pt/app/just-in-case/id1463794084"><img src="../assets/img/app-store@2x.png" alt=""></a>
					</div>
					<p class="sub-text">* <router-link :to="{name:'detalhe',params: { zone: 'europa', days: 90, value:57.11, people: 1, example:true  }}">Ver detalhe do exemplo de preço indicado.</router-link></p>
					<!--<p class="sub-text">Prémio total diário, por pessoa, para uma viagem de 90 dias a Itália. Válido para seguros contratados até 31/03/2020.</p>-->
					<p class="sub-text">Esta informação não dispensa a consulta da informação pré-contratual e contratual legalmente exigida.</p>

					<div class="ref-panel">
						<p class="sub-text min padding-top text-uppercase">Vê aqui:</p>
						<ul>
							<li>
								<a href="https://www.fidelidade.pt/justincase/Informacoes_Pre-Contratuais.pdf" class="d-flex">
									<div class="bullet-image"><img src="../assets/img/icon-doc@2x.png"></div>
									<span>Informações Pré-Contratuais (inclui Documento de Informação sobre o Produto de Seguros)</span>
								</a>
							</li>
							<li>
								<a href="https://www.fidelidade.pt/justincase/Condicoes_Gerais_e_Especiais.pdf" class="d-flex">
									<div class="bullet-image"><img src="../assets/img/icon-doc@2x.png"></div>
									<span>Condições Gerais e Especiais</span>
								</a>
							</li>
							<li>
								<a href="https://www.fidelidade.pt/justincase/Condicoes_Gerais_de_Utilizacao_Site_Just_in_Case.pdf" class="d-flex">
									<div class="bullet-image"><img src="../assets/img/icon-doc@2x.png"></div>
									<span>Política de Privacidade e Proteção de Dados Pessoais</span>
								</a>
							</li>
							<li>
								<a href="https://www.fidelidade.pt/justincase/Condicoes_Gerais_de_Utilizacao_Site_Just_in_Case.pdf" class="d-flex">
									<div class="bullet-image"><img src="../assets/img/icon-doc@2x.png"></div>
									<span>Condições Gerais de Utilização</span>
								</a>
							</li>
						</ul>
						<p class="sub-text">
							Um seguro da Fidelidade – Companhia de Seguros, S.A.
						</p>
					</div>
					<div class="bottom-footer">
						<div class="back-top">
							<a href="#">
								<div class="arrow-top"></div>
								<p>Voltar ao topo</p>
							</a>
						</div>
						<p class="sub-text pos-text padding-top">
							Just in Case é uma marca registada da Fidelidade - Companhia de Seguros, S.A. com sede no Largo do Calhariz, n.º 30 Lisboa 1249-001 · Portugal
						</p>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import { store } from "../store";
import { EventBus } from '../event-bus';

export default {
	name: 'Footer',
	data() {
		return {
			paramZone: '',
			paramPeople: 0,
			paramDays: 0,
			paramValue: '',
			showDetailLink: false
		}
	},
	computed: {
		detalheSimulUrl() {
			return '/detalhe-simulacao/' + this.paramZone + '/' + this.paramPeople + '/' + this.paramDays + '/' + this.paramValue;
		},
		isIos() {
			if (window.innerWidth < 768) {
				if(navigator.userAgent.toLowerCase().indexOf("iphone") > -1){
					return 1;
				}
				return 0;
			}
			return 1;
		},
		isAndroid() {
			if (window.innerWidth < 768) {
				if(navigator.userAgent.toLowerCase().indexOf("android") > -1){
					return 1;
				}
				return 0;
			}
			return 1;
		}
	},
	mounted() {
		// Listen to the event that tells us that we have the values
		EventBus.$on('simulation-values-set', getValuesFromSimulation => {
			this.paramZone = store.zone;
			this.paramPeople = store.people;
			this.paramDays = store.days;
			this.paramValue = store.value;
			this.showDetailLink = true;
		});

		// Listen to the event that tells us that we have the values
		EventBus.$on('hide-simulation-link', hideSimulationLink => {
			this.showDetailLink = false;
		});
		
	},
	methods: {
		grplay: function() {
			dataLayer.push({'event': 'eventPage', 'eventAction': 'Click', 'eventCategory': 'Just in Case - App Stores', 'eventLabel': 'Rodapé_Google Play'});
		},
		arstore: function() {
			dataLayer.push({'event': 'eventPage', 'eventAction': 'Click', 'eventCategory': 'Just in Case - App Stores', 'eventLabel': 'Rodapé_App Store'});

		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/_variables.scss';

.footer {
	background-color: $color-footer-bg;
	position: relative;
	z-index: 2;
	min-height: 40rem;
	padding-top: 60px;

	.content-wrapper {
		margin-bottom: 10px;
	}

	h3 {
		font-size: 20px;
		line-height: 32px;
		letter-spacing: 0.23px;
		color: $color-white;

		// Exception
		@media (min-width: 340px) {
			font-size: 22px;
		}

		@media (min-width: 768px) {
			font-size: 36px;
			line-height: 46px;
		}
	}

	.apps-panel {
		margin: 0 0 40px 0;

		a {
			display: block;
			height: 100%;
		}

		img {
			height: auto;
		}
	}

	.ref-panel {
		@media (min-width: 768px) {
			padding-bottom: 0px;
		}
		ul {
			margin: 20px 0 40px 0px;

			@media (min-width: 768px) {
				margin: 0px 0 20px 0px;
			}

			li {
				text-align: left;
				line-height: 18px;
				padding-bottom: 15px;
	
				a {
					.bullet-image {
						width: 24px;
						height: 24px;
						margin-right: 8px;

						img {
							height: 100%;
						}
					}

					span {
						color: $color-white;
						font-weight: 200;
						font-size: 14px;
						line-height: 20px;
						letter-spacing: 0.23px;
						text-decoration: underline;
					}
				}
			}
		}
	}

	.back-top {
		margin-top: 30px;

		a {
			display: block;
			width: 100%;
		}

		p {
			font-size: 16px;
			font-weight: 200;
			line-height: 24px;
			letter-spacing: 0.2px;
			color: $color-white;
		}

		.arrow-top {
			border: solid $color-white;
			border-width: 0 1px 1px 0;
			display: inline-block;
			padding: 4px;

			transform: rotate(225deg);

			@media (min-width: 768px) {
				margin-right: 15px;
				margin-top: 10px;
				width: 10px;
    			height: 10px;
			}
		}
	}

	.sub-text {
		color: $color-white;
		font-weight: 200;
		font-size: 14px;
		line-height: 20px;
		letter-spacing: 0.23px;
		text-align: left;

		@media (min-width: 768px) {
			padding-bottom: 10px;
		}

		&.min {
			margin-top: 30px;
			font-size: 10px;
		}

		&.padding-top {
			margin-top: 50px;
			@media (min-width: 768px) {
				margin-top: 10px;
			}
		}

		a {
			color: $color-white;
			text-decoration: underline;
		}
	}
	.bottom-footer {
		align-items: center;
		.pos-text {
			text-align: center;
			@media (min-width: 768px) {
				text-align: left;
			}
		}
		@media (min-width: 768px) {
			display: flex;
			justify-content: space-between;
		}
		.back-top {
			@media (min-width: 768px) {
				order: 2;
				margin-top: 0px;
				min-width: 150px;
				margin-left: 20px;
			}
			a {
				@media (min-width: 768px) {
					display: flex;
				}
			}
		}
	}
}
</style>