<template>
	<section id="seguro" class="seguro">
		<div class="container">
			<div class="row" >
				<Modal v-if="showModal" @close="showModal = false">

				</Modal>
				<div class="top-panel">
					<span>Simula o teu seguro de viagem</span>
					<div class="image mx-auto"><img src="../assets/img/fidelidade_logo-red@2x.png"></div>
					<a href="#seguro"><div class="arrow"></div></a>
				</div>
				<div  class="col-12 col-md-6 seguro-wrapper">
					<div class="simulador-container" v-bind:class="{show: isSimulation}">
						<div class="d-none d-md-block">
							<p class="simulator-title">Faz uma simulação do seguro para a tua viagem</p>
							<div class="image mx-auto text-center"><img src="../assets/img/fidelidade_logo-red@2x.png"></div>
						</div>
						<div class="top text-center"><span>Protege a tua viagem e da tua família,<br>em qualquer parte do mundo. #justincase</span></div>
						<div class="separator"></div>
						<div class="simulador-wrapper">
							<div class="destino">
								<span>Destino</span>
								<div class="radio-button-area first">
									<p-radio class="p-icon p-round" name="destino" color="primary" id="europa" checked value="europa" v-model="zonePicked">
										<i slot="extra" class="icon mdi mdi-check"></i>
										<span class="radio-text">Europa e Países da Bacia do Mediterrâneo</span>
									</p-radio>
									<div class="help" v-tooltip="tooltipEuropaSettings"><span><i class="icon mdi mdi-help"></i></span></div>
								</div>
								<div class="radio-button-area">
									<p-radio class="p-icon p-round" name="destino" color="primary" id="resto-mundo" value="mundo" v-model="zonePicked">
										<i slot="extra" class="icon mdi mdi-check"></i>
										<span class="radio-text">Resto do Mundo</span>
									</p-radio>
								</div>
							</div>
							<div class="pessoas-seguras d-flex justify-content-between">
								<span>Pessoas Seguras</span>
								<div class="simulador-buttons d-flex" id="simulador-pessoas">
									<div class="minus button" v-bind:class="{active: isButtonMinusPessoasActive}" v-on:click="minusButtonClick"><span>-</span></div>
									<div class="value text-center">
										<input
											v-on:input.stop="setpessoasValue($event, $event.target.value)"
											v-on:change.stop="setpessoasValue($event, $event.target.value)"
											type="number" 
											:value="pessoasValue"
										>
									</div>
									<template v-if="!isButtonPlusPessoasActive">
										<div class="plus button" v-bind:class="{active: isButtonPlusPessoasActive}" v-on:click="plusButtonClick" v-tooltip="tooltipSettings"><span>+</span></div>
									</template>
									<template v-else>
										<div class="plus button" v-bind:class="{active: isButtonPlusPessoasActive}" v-on:click="plusButtonClick"><span>+</span></div>
									</template>
								</div>
							</div>
							<div class="dias-viagem d-flex justify-content-between">
								<span>Dias de viagem</span>
								<div class="simulador-buttons d-flex" id="simulador-dias">
									<div class="minus button" v-bind:class="{active: isButtonMinusDiasActive}" v-on:click="minusButtonClick"><span>-</span></div>
									<div class="value text-center"><!--<span>{{ diasValue }}</span>-->
										<input
											v-on:input.stop="setdiasValue($event, $event.target.value)"
											type="number" 
											:value="diasValue"
										>
									</div>
									<div class="plus button" v-bind:class="{active: isButtonPlusDiasActive}" v-on:click="plusButtonClick"><span>+</span></div>
								</div>
							</div>
						</div>
						<div class="separator"></div>
						<div class="button-simulador-wrapper">
							<div class="button-simulador" v-on:click="simularClick" v-bind:class="{active: isActive}"><span>Simular</span></div>
						</div>
					</div>
					<div class="result-container" v-bind:class="{show: !isSimulation}">
						<div class="d-none d-md-block">
							<p class="simulator-title">Faz uma simulação do seguro para a tua viagem</p>
							<div class="image mx-auto text-center"><img src="../assets/img/fidelidade_logo-red@2x.png"></div>
						</div>
						<div class="top text-center"><span>Protege a tua viagem e da tua família,<br>em qualquer parte do mundo. #justincase</span></div>
						<div class="separator"></div>
						<div class="simulador-wrapper">
							<div class="destino d-flex justify-content-between">
								<span>Destino</span>
								<div class="result-area first"><span class="result-area-text">{{ resultAreaValue }}</span></div>
							</div> 
							<div class="pessoas-seguras d-flex justify-content-between">
								<span>Pessoas Seguras</span>
								<div class="simulador-values d-flex">
									<div class="value text-center"><span>{{ pessoasValue }}</span></div>
								</div>
							</div>
							<div class="dias-viagem d-flex justify-content-between">
								<span>Dias de viagem</span>
								<div class="simulador-values d-flex">
									<div class="value text-center"><span>{{ diasValue }}</span></div>
								</div>
							</div>
							<div class="result-simulacao d-flex justify-content-between">
								<span>O teu seguro de viagem</span>
								<div class="simulador-values d-flex">
									<div class="result-value text-center"><span>{{ resultValue }}€ / dia **</span></div>
								</div>
							</div>
						</div>
						<div class="separator"></div>
						<div class="result-download-text text-center"><span>Descarrega a app, faz a tua simulação<br>e vê o preço para a tua viagem!</span></div>
						<div class="button-simulador-wrapper">
							<!--<div class="button-simulador" id="show-modal" @click="showModal = true"><span>Receber Link via SMS</span></div>-->
							<!--<div class="link-simulador" v-on:click="backToSimulation"><span>Simular novamente</span></div>-->
							<div class="button-simulador" v-on:click="backToSimulation"><span>Simular novamente</span></div>
							<!--<p v-on:click="detalhe" class="link-simulador">** <a v-bind:href="detalheSimulUrl"><span>Ver detalhe da simulação.</span></a></p>-->
							<p v-on:click="detalhe" class="link-simulador">** <router-link :to="{name:'detalhe',params: { zone:`${this.zonePicked}`, days:`${this.diasValue}`, value:`${this.resultTotal}`, people:`${this.pessoasValue}`, example:false }}"><span>Ver detalhe da simulação.</span></router-link></p>
						</div>
					</div>
				</div>
				<div class="col-12 col-md-6 info-wrapper">
					<h3>Seguro de viagem <br><span>desde</span> 0,63€ / dia *</h3>
					<h4>* <router-link :to="{name:'detalhe',params: { zone: 'europa', days: 90, value:57.11, people: 1, example:true  }}">Ver detalhe do exemplo de preço indicado</router-link></h4>
					<p class="below-title">Aproveita a tua viagem, sabendo que nós vamos estar lá para te proteger 24/7, 365 dias por ano, Just in Case.</p>
					<hr>
					<span class="checked-list-title">
						Principais coberturas incluídas:
					</span>

					<ul class="checked-list">
						<li>Despesas médicas</li>
						<li>Bagagem não acompanhada</li>
						<li>Assistência em viagem</li>
					</ul>

					<span class="checked-list-title">
						Coberturas opcionais:
					</span>

					<ul class="checked-list">
						<li>Bagagem acompanhada</li>
						<li>Cancelamento e redução de viagem</li>
					</ul>
				</div>
				<div class="col-12 text-center splash-wrapper">

					<div class="col-md-6 payments">
						<img src="../assets/img/payments.png">
					</div>

					<div class="col-md-6 apps-panel d-flex justify-content-center">
						<a  v-on:click="gplay" v-show="isAndroid" href="https://play.google.com/store/apps/details?id=pt.fidelidade.b2c.JustinCase&hl=pt_PT"><img src="../assets/img/play-store@2x.png" alt=""></a>
						<a  v-on:click="astore" v-show="isIos" href="https://apps.apple.com/pt/app/just-in-case/id1463794084"><img src="../assets/img/app-store@2x.png" alt=""></a>
					</div>

				</div>
			</div>
		</div>
		<div class="bottom-panel"></div>
	</section>
</template>

<script>
import Modal from '@/components/Modal.vue'
import { mutations } from "../store";
import { EventBus } from '../event-bus';

export default {
	name: 'Seguro',
	components: {
		Modal
	},
	data() {
		return {
			tooltipSettings: {
				content: 'Para adicionar mais pessoas, faz o download da app.',
				placement:'top-end',
				offset: 0,
				delay: {
					show: 0,
					hide: 200
				}
			},
			tooltipEuropaSettings: {
				content: 'Europa, Argélia, Egipto, Israel, Líbano, Líbia, Marrocos, Síria, Turquia e Tunísia.',
				classes: ['europe'],
				placement:'top-end',
				offset: 0,
				delay: {
					show: 0,
					hide: 200
				},
				trigger: 'click'
			},
			showDetailLink: false,
			pessoasValue: 1,
			diasValue: 1,
			zonePicked: 'europa',
			resultAreaValue: '',
			resultValue: 0,
			resultTotal: 0,
			isSimulation: true,
			isActive: false,
			isButtonMinusPessoasActive: false,
			isButtonMinusDiasActive: false,
			isButtonPlusPessoasActive: true,
			isButtonPlusDiasActive: true,
			showModal: false,
			premioTotalMundo: [
				[14.03,25.91,37.79,49.67,61.54,73.42,85.3,97.18,109.06],
				[14.03,25.91,37.79,49.67,61.54,73.42,85.3,97.18,109.06],
				[18.97,35.8,52.62,69.45,86.27,103.09,119.92,136.74,153.56],
				[24.23,46.31,68.39,90.47,112.55,134.63,156.71,178.79,200.87],
				[24.23,46.31,68.39,90.47,112.55,134.63,156.71,178.79,200.87],
				[31.64,61.12,90.61,120.1,149.59,179.07,208.56,238.05,267.54],
				[31.64,61.12,90.61,120.1,149.59,179.07,208.56,238.05,267.54],
				[31.64,61.12,90.61,120.1,149.59,179.07,208.56,238.05,267.54],
				[36.38,70.61,104.83,139.06,173.29,207.52,241.75,275.97,310.2],
				[36.38,70.61,104.83,139.06,173.29,207.52,241.75,275.97,310.2],
				[36.38,70.61,104.83,139.06,173.29,207.52,241.75,275.97,310.2],
				[36.38,70.61,104.83,139.06,173.29,207.52,241.75,275.97,310.2],
				[40.09,78.02,115.96,153.9,191.83,229.77,267.71,305.64,343.58],
				[40.09,78.02,115.96,153.9,191.83,229.77,267.71,305.64,343.58],
				[40.09,78.02,115.96,153.9,191.83,229.77,267.71,305.64,343.58],
				[40.09,78.02,115.96,153.9,191.83,229.77,267.71,305.64,343.58],
				[48.92,95.7,142.47,189.24,236.02,282.79,329.56,376.34,423.11],
				[48.92,95.7,142.47,189.24,236.02,282.79,329.56,376.34,423.11],
				[48.92,95.7,142.47,189.24,236.02,282.79,329.56,376.34,423.11],
				[48.92,95.7,142.47,189.24,236.02,282.79,329.56,376.34,423.11],
				[48.92,95.7,142.47,189.24,236.02,282.79,329.56,376.34,423.11],
				[48.92,95.7,142.47,189.24,236.02,282.79,329.56,376.34,423.11],
				[54.45,106.75,159.05,211.35,263.64,315.94,368.24,420.54,472.84],
				[54.45,106.75,159.05,211.35,263.64,315.94,368.24,420.54,472.84],
				[54.45,106.75,159.05,211.35,263.64,315.94,368.24,420.54,472.84],
				[54.45,106.75,159.05,211.35,263.64,315.94,368.24,420.54,472.84],
				[54.45,106.75,159.05,211.35,263.64,315.94,368.24,420.54,472.84],
				[54.45,106.75,159.05,211.35,263.64,315.94,368.24,420.54,472.84],
				[54.45,106.75,159.05,211.35,263.64,315.94,368.24,420.54,472.84],
				[54.45,106.75,159.05,211.35,263.64,315.94,368.24,420.54,472.84],
				[54.45,106.75,159.05,211.35,263.64,315.94,368.24,420.54,472.84],
				[66.38,130.61,194.84,259.08,323.31,387.54,451.77,516,580.23],
				[66.38,130.61,194.84,259.08,323.31,387.54,451.77,516,580.23],
				[66.38,130.61,194.84,259.08,323.31,387.54,451.77,516,580.23],
				[66.38,130.61,194.84,259.08,323.31,387.54,451.77,516,580.23],
				[66.38,130.61,194.84,259.08,323.31,387.54,451.77,516,580.23],
				[66.38,130.61,194.84,259.08,323.31,387.54,451.77,516,580.23],
				[66.38,130.61,194.84,259.08,323.31,387.54,451.77,516,580.23],
				[66.38,130.61,194.84,259.08,323.31,387.54,451.77,516,580.23],
				[66.38,130.61,194.84,259.08,323.31,387.54,451.77,516,580.23],
				[66.38,130.61,194.84,259.08,323.31,387.54,451.77,516,580.23],
				[66.38,130.61,194.84,259.08,323.31,387.54,451.77,516,580.23],
				[66.38,130.61,194.84,259.08,323.31,387.54,451.77,516,580.23],
				[66.38,130.61,194.84,259.08,323.31,387.54,451.77,516,580.23],
				[66.38,130.61,194.84,259.08,323.31,387.54,451.77,516,580.23],
				[66.38,130.61,194.84,259.08,323.31,387.54,451.77,516,580.23],
				[66.38,130.61,194.84,259.08,323.31,387.54,451.77,516,580.23],
				[66.38,130.61,194.84,259.08,323.31,387.54,451.77,516,580.23],
				[66.38,130.61,194.84,259.08,323.31,387.54,451.77,516,580.23],
				[66.38,130.61,194.84,259.08,323.31,387.54,451.77,516,580.23],
				[66.38,130.61,194.84,259.08,323.31,387.54,451.77,516,580.23],
				[66.38,130.61,194.84,259.08,323.31,387.54,451.77,516,580.23],
				[66.38,130.61,194.84,259.08,323.31,387.54,451.77,516,580.23],
				[66.38,130.61,194.84,259.08,323.31,387.54,451.77,516,580.23],
				[66.38,130.61,194.84,259.08,323.31,387.54,451.77,516,580.23],
				[66.38,130.61,194.84,259.08,323.31,387.54,451.77,516,580.23],
				[66.38,130.61,194.84,259.08,323.31,387.54,451.77,516,580.23],
				[66.38,130.61,194.84,259.08,323.31,387.54,451.77,516,580.23],
				[66.38,130.61,194.84,259.08,323.31,387.54,451.77,516,580.23],
				[66.38,130.61,194.84,259.08,323.31,387.54,451.77,516,580.23],
				[79.02,155.9,232.77,309.64,386.52,463.39,540.26,617.14,694.01],
				[79.02,155.9,232.77,309.64,386.52,463.39,540.26,617.14,694.01],
				[79.02,155.9,232.77,309.64,386.52,463.39,540.26,617.14,694.01],
				[79.02,155.9,232.77,309.64,386.52,463.39,540.26,617.14,694.01],
				[79.02,155.9,232.77,309.64,386.52,463.39,540.26,617.14,694.01],
				[79.02,155.9,232.77,309.64,386.52,463.39,540.26,617.14,694.01],
				[79.02,155.9,232.77,309.64,386.52,463.39,540.26,617.14,694.01],
				[79.02,155.9,232.77,309.64,386.52,463.39,540.26,617.14,694.01],
				[79.02,155.9,232.77,309.64,386.52,463.39,540.26,617.14,694.01],
				[79.02,155.9,232.77,309.64,386.52,463.39,540.26,617.14,694.01],
				[79.02,155.9,232.77,309.64,386.52,463.39,540.26,617.14,694.01],
				[79.02,155.9,232.77,309.64,386.52,463.39,540.26,617.14,694.01],
				[79.02,155.9,232.77,309.64,386.52,463.39,540.26,617.14,694.01],
				[79.02,155.9,232.77,309.64,386.52,463.39,540.26,617.14,694.01],
				[79.02,155.9,232.77,309.64,386.52,463.39,540.26,617.14,694.01],
				[79.02,155.9,232.77,309.64,386.52,463.39,540.26,617.14,694.01],
				[79.02,155.9,232.77,309.64,386.52,463.39,540.26,617.14,694.01],
				[79.02,155.9,232.77,309.64,386.52,463.39,540.26,617.14,694.01],
				[79.02,155.9,232.77,309.64,386.52,463.39,540.26,617.14,694.01],
				[79.02,155.9,232.77,309.64,386.52,463.39,540.26,617.14,694.01],
				[79.02,155.9,232.77,309.64,386.52,463.39,540.26,617.14,694.01],
				[79.02,155.9,232.77,309.64,386.52,463.39,540.26,617.14,694.01],
				[79.02,155.9,232.77,309.64,386.52,463.39,540.26,617.14,694.01],
				[79.02,155.9,232.77,309.64,386.52,463.39,540.26,617.14,694.01],
				[79.02,155.9,232.77,309.64,386.52,463.39,540.26,617.14,694.01],
				[79.02,155.9,232.77,309.64,386.52,463.39,540.26,617.14,694.01],
				[79.02,155.9,232.77,309.64,386.52,463.39,540.26,617.14,694.01],
				[79.02,155.9,232.77,309.64,386.52,463.39,540.26,617.14,694.01],
				[79.02,155.9,232.77,309.64,386.52,463.39,540.26,617.14,694.01],
				[79.02,155.9,232.77,309.64,386.52,463.39,540.26,617.14,694.01],
			],
			premioTotalEuropa: [
				[10.4,18.64,26.89,35.13,43.38,51.62,59.87,68.11,76.36],
				[10.4,18.64,26.89,35.13,43.38,51.62,59.87,68.11,76.36],
				[12.88,23.61,34.34,45.06,55.79,66.52,77.25,87.98,98.71],
				[15.71,29.26,42.82,56.37,69.93,83.48,97.04,110.6,124.15],
				[15.71,29.26,42.82,56.37,69.93,83.48,97.04,110.6,124.15],
				[19.47,36.79,54.1,71.42,88.74,106.06,123.38,140.7,158.01],
				[19.47,36.79,54.1,71.42,88.74,106.06,123.38,140.7,158.01],
				[19.47,36.79,54.1,71.42,88.74,106.06,123.38,140.7,158.01],
				[22.99,43.84,64.68,85.53,106.37,127.22,148.06,168.9,189.75],
				[22.99,43.84,64.68,85.53,106.37,127.22,148.06,168.9,189.75],
				[22.99,43.84,64.68,85.53,106.37,127.22,148.06,168.9,189.75],
				[22.99,43.84,64.68,85.53,106.37,127.22,148.06,168.9,189.75],
				[25.49,48.83,72.16,95.5,118.84,142.18,165.52,188.86,212.19],
				[25.49,48.83,72.16,95.5,118.84,142.18,165.52,188.86,212.19],
				[25.49,48.83,72.16,95.5,118.84,142.18,165.52,188.86,212.19],
				[25.49,48.83,72.16,95.5,118.84,142.18,165.52,188.86,212.19],
				[30.86,59.58,88.29,117,145.72,174.43,203.14,231.86,260.57],
				[30.86,59.58,88.29,117,145.72,174.43,203.14,231.86,260.57],
				[30.86,59.58,88.29,117,145.72,174.43,203.14,231.86,260.57],
				[30.86,59.58,88.29,117,145.72,174.43,203.14,231.86,260.57],
				[30.86,59.58,88.29,117,145.72,174.43,203.14,231.86,260.57],
				[30.86,59.58,88.29,117,145.72,174.43,203.14,231.86,260.57],
				[35.11,68.07,101.03,133.99,166.95,199.91,232.87,265.83,298.79],
				[35.11,68.07,101.03,133.99,166.95,199.91,232.87,265.83,298.79],
				[35.11,68.07,101.03,133.99,166.95,199.91,232.87,265.83,298.79],
				[35.11,68.07,101.03,133.99,166.95,199.91,232.87,265.83,298.79],
				[35.11,68.07,101.03,133.99,166.95,199.91,232.87,265.83,298.79],
				[35.11,68.07,101.03,133.99,166.95,199.91,232.87,265.83,298.79],
				[35.11,68.07,101.03,133.99,166.95,199.91,232.87,265.83,298.79],
				[35.11,68.07,101.03,133.99,166.95,199.91,232.87,265.83,298.79],
				[35.11,68.07,101.03,133.99,166.95,199.91,232.87,265.83,298.79],
				[45.74,89.33,132.92,176.52,220.11,263.7,307.29,350.88,394.47],
				[45.74,89.33,132.92,176.52,220.11,263.7,307.29,350.88,394.47],
				[45.74,89.33,132.92,176.52,220.11,263.7,307.29,350.88,394.47],
				[45.74,89.33,132.92,176.52,220.11,263.7,307.29,350.88,394.47],
				[45.74,89.33,132.92,176.52,220.11,263.7,307.29,350.88,394.47],
				[45.74,89.33,132.92,176.52,220.11,263.7,307.29,350.88,394.47],
				[45.74,89.33,132.92,176.52,220.11,263.7,307.29,350.88,394.47],
				[45.74,89.33,132.92,176.52,220.11,263.7,307.29,350.88,394.47],
				[45.74,89.33,132.92,176.52,220.11,263.7,307.29,350.88,394.47],
				[45.74,89.33,132.92,176.52,220.11,263.7,307.29,350.88,394.47],
				[45.74,89.33,132.92,176.52,220.11,263.7,307.29,350.88,394.47],
				[45.74,89.33,132.92,176.52,220.11,263.7,307.29,350.88,394.47],
				[45.74,89.33,132.92,176.52,220.11,263.7,307.29,350.88,394.47],
				[45.74,89.33,132.92,176.52,220.11,263.7,307.29,350.88,394.47],
				[45.74,89.33,132.92,176.52,220.11,263.7,307.29,350.88,394.47],
				[45.74,89.33,132.92,176.52,220.11,263.7,307.29,350.88,394.47],
				[45.74,89.33,132.92,176.52,220.11,263.7,307.29,350.88,394.47],
				[45.74,89.33,132.92,176.52,220.11,263.7,307.29,350.88,394.47],
				[45.74,89.33,132.92,176.52,220.11,263.7,307.29,350.88,394.47],
				[45.74,89.33,132.92,176.52,220.11,263.7,307.29,350.88,394.47],
				[45.74,89.33,132.92,176.52,220.11,263.7,307.29,350.88,394.47],
				[45.74,89.33,132.92,176.52,220.11,263.7,307.29,350.88,394.47],
				[45.74,89.33,132.92,176.52,220.11,263.7,307.29,350.88,394.47],
				[45.74,89.33,132.92,176.52,220.11,263.7,307.29,350.88,394.47],
				[45.74,89.33,132.92,176.52,220.11,263.7,307.29,350.88,394.47],
				[45.74,89.33,132.92,176.52,220.11,263.7,307.29,350.88,394.47],
				[45.74,89.33,132.92,176.52,220.11,263.7,307.29,350.88,394.47],
				[45.74,89.33,132.92,176.52,220.11,263.7,307.29,350.88,394.47],
				[45.74,89.33,132.92,176.52,220.11,263.7,307.29,350.88,394.47],
				[57.11,112.08,167.04,222.01,276.97,331.94,386.9,441.87,496.83],
				[57.11,112.08,167.04,222.01,276.97,331.94,386.9,441.87,496.83],
				[57.11,112.08,167.04,222.01,276.97,331.94,386.9,441.87,496.83],
				[57.11,112.08,167.04,222.01,276.97,331.94,386.9,441.87,496.83],
				[57.11,112.08,167.04,222.01,276.97,331.94,386.9,441.87,496.83],
				[57.11,112.08,167.04,222.01,276.97,331.94,386.9,441.87,496.83],
				[57.11,112.08,167.04,222.01,276.97,331.94,386.9,441.87,496.83],
				[57.11,112.08,167.04,222.01,276.97,331.94,386.9,441.87,496.83],
				[57.11,112.08,167.04,222.01,276.97,331.94,386.9,441.87,496.83],
				[57.11,112.08,167.04,222.01,276.97,331.94,386.9,441.87,496.83],
				[57.11,112.08,167.04,222.01,276.97,331.94,386.9,441.87,496.83],
				[57.11,112.08,167.04,222.01,276.97,331.94,386.9,441.87,496.83],
				[57.11,112.08,167.04,222.01,276.97,331.94,386.9,441.87,496.83],
				[57.11,112.08,167.04,222.01,276.97,331.94,386.9,441.87,496.83],
				[57.11,112.08,167.04,222.01,276.97,331.94,386.9,441.87,496.83],
				[57.11,112.08,167.04,222.01,276.97,331.94,386.9,441.87,496.83],
				[57.11,112.08,167.04,222.01,276.97,331.94,386.9,441.87,496.83],
				[57.11,112.08,167.04,222.01,276.97,331.94,386.9,441.87,496.83],
				[57.11,112.08,167.04,222.01,276.97,331.94,386.9,441.87,496.83],
				[57.11,112.08,167.04,222.01,276.97,331.94,386.9,441.87,496.83],
				[57.11,112.08,167.04,222.01,276.97,331.94,386.9,441.87,496.83],
				[57.11,112.08,167.04,222.01,276.97,331.94,386.9,441.87,496.83],
				[57.11,112.08,167.04,222.01,276.97,331.94,386.9,441.87,496.83],
				[57.11,112.08,167.04,222.01,276.97,331.94,386.9,441.87,496.83],
				[57.11,112.08,167.04,222.01,276.97,331.94,386.9,441.87,496.83],
				[57.11,112.08,167.04,222.01,276.97,331.94,386.9,441.87,496.83],
				[57.11,112.08,167.04,222.01,276.97,331.94,386.9,441.87,496.83],
				[57.11,112.08,167.04,222.01,276.97,331.94,386.9,441.87,496.83],
				[57.11,112.08,167.04,222.01,276.97,331.94,386.9,441.87,496.83],
				[57.11,112.08,167.04,222.01,276.97,331.94,386.9,441.87,496.83],
			],
			premioDiarioEuropa: [
				[10.4,18.64,26.89,35.13,43.38,51.62,59.87,68.11,76.36],
				[5.2,9.32,13.445,17.565,21.69,25.81,29.935,34.055,38.18],
				[4.29333333333333,7.87,11.4466666666667,15.02,18.5966666666667,22.1733333333333,25.75,29.3266666666667,32.9033333333333],
				[3.9275,7.315,10.705,14.0925,17.4825,20.87,24.26,27.65,31.0375],
				[3.142,5.852,8.564,11.274,13.986,16.696,19.408,22.12,24.83],
				[3.245,6.13166666666667,9.01666666666667,11.9033333333333,14.79,17.6766666666667,20.5633333333333,23.45,26.335],
				[2.78142857142857,5.25571428571429,7.72857142857143,10.2028571428571,12.6771428571429,15.1514285714286,17.6257142857143,20.1,22.5728571428571],
				[2.43375,4.59875,6.7625,8.9275,11.0925,13.2575,15.4225,17.5875,19.75125],
				[2.55444444444444,4.87111111111111,7.18666666666667,9.50333333333333,11.8188888888889,14.1355555555556,16.4511111111111,18.7666666666667,21.0833333333333],
				[2.299,4.384,6.468,8.553,10.637,12.722,14.806,16.89,18.975],
				[2.09,3.98545454545455,5.88,7.77545454545455,9.67,11.5654545454545,13.46,15.3545454545455,17.25],
				[1.91583333333333,3.65333333333333,5.39,7.1275,8.86416666666667,10.6016666666667,12.3383333333333,14.075,15.8125],
				[1.96076923076923,3.75615384615385,5.55076923076923,7.34615384615385,9.14153846153846,10.9369230769231,12.7323076923077,14.5276923076923,16.3223076923077],
				[1.82071428571429,3.48785714285714,5.15428571428571,6.82142857142857,8.48857142857143,10.1557142857143,11.8228571428571,13.49,15.1564285714286],
				[1.69933333333333,3.25533333333333,4.81066666666667,6.36666666666667,7.92266666666667,9.47866666666667,11.0346666666667,12.5906666666667,14.146],
				[1.593125,3.051875,4.51,5.96875,7.4275,8.88625,10.345,11.80375,13.261875],
				[1.81529411764706,3.50470588235294,5.19352941176471,6.88235294117647,8.57176470588235,10.2605882352941,11.9494117647059,13.6388235294118,15.3276470588235],
				[1.71444444444444,3.31,4.905,6.5,8.09555555555556,9.69055555555556,11.2855555555556,12.8811111111111,14.4761111111111],
				[1.62421052631579,3.13578947368421,4.64684210526316,6.15789473684211,7.66947368421053,9.18052631578947,10.6915789473684,12.2031578947368,13.7142105263158],
				[1.543,2.979,4.4145,5.85,7.286,8.7215,10.157,11.593,13.0285],
				[1.46952380952381,2.83714285714286,4.20428571428571,5.57142857142857,6.93904761904762,8.30619047619048,9.67333333333333,11.0409523809524,12.4080952380952],
				[1.40272727272727,2.70818181818182,4.01318181818182,5.31818181818182,6.62363636363636,7.92863636363636,9.23363636363636,10.5390909090909,11.8440909090909],
				[1.52652173913043,2.9595652173913,4.39260869565217,5.82565217391304,7.25869565217391,8.69173913043478,10.1247826086957,11.5578260869565,12.9908695652174],
				[1.46291666666667,2.83625,4.20958333333333,5.58291666666667,6.95625,8.32958333333333,9.70291666666667,11.07625,12.4495833333333],
				[1.4044,2.7228,4.0412,5.3596,6.678,7.9964,9.3148,10.6332,11.9516],
				[1.35038461538462,2.61807692307692,3.88576923076923,5.15346153846154,6.42115384615385,7.68884615384615,8.95653846153846,10.2242307692308,11.4919230769231],
				[1.30037037037037,2.52111111111111,3.74185185185185,4.96259259259259,6.18333333333333,7.40407407407407,8.62481481481482,9.84555555555555,11.0662962962963],
				[1.25392857142857,2.43107142857143,3.60821428571429,4.78535714285714,5.9625,7.13964285714286,8.31678571428571,9.49392857142857,10.6710714285714],
				[1.21068965517241,2.34724137931034,3.48379310344828,4.62034482758621,5.75689655172414,6.89344827586207,8.03,9.16655172413793,10.3031034482759],
				[1.17033333333333,2.269,3.36766666666667,4.46633333333333,5.565,6.66366666666667,7.76233333333333,8.861,9.95966666666667],
				[1.13258064516129,2.1958064516129,3.25903225806452,4.32225806451613,5.38548387096774,6.44870967741935,7.51193548387097,8.57516129032258,9.63838709677419],
				[1.429375,2.7915625,4.15375,5.51625,6.8784375,8.240625,9.6028125,10.965,12.3271875],
				[1.38606060606061,2.7069696969697,4.02787878787879,5.34909090909091,6.67,7.99090909090909,9.31181818181818,10.6327272727273,11.9536363636364],
				[1.34529411764706,2.62735294117647,3.90941176470588,5.19176470588235,6.47382352941177,7.75588235294118,9.03794117647059,10.32,11.6020588235294],
				[1.30685714285714,2.55228571428571,3.79771428571429,5.04342857142857,6.28885714285714,7.53428571428571,8.77971428571429,10.0251428571429,11.2705714285714],
				[1.27055555555556,2.48138888888889,3.69222222222222,4.90333333333333,6.11416666666667,7.325,8.53583333333333,9.74666666666667,10.9575],
				[1.23621621621622,2.41432432432432,3.59243243243243,4.77081081081081,5.94891891891892,7.12702702702703,8.30513513513514,9.48324324324324,10.6613513513514],
				[1.20368421052632,2.35078947368421,3.4978947368421,4.64526315789474,5.79236842105263,6.93947368421053,8.08657894736842,9.23368421052631,10.3807894736842],
				[1.17282051282051,2.29051282051282,3.40820512820513,4.52615384615385,5.64384615384615,6.76153846153846,7.87923076923077,8.99692307692308,10.1146153846154],
				[1.1435,2.23325,3.323,4.413,5.50275,6.5925,7.68225,8.772,9.86175],
				[1.11560975609756,2.17878048780488,3.24195121951219,4.30536585365854,5.36853658536585,6.43170731707317,7.49487804878049,8.55804878048781,9.62121951219512],
				[1.08904761904762,2.12690476190476,3.1647619047619,4.20285714285714,5.24071428571429,6.27857142857143,7.31642857142857,8.35428571428571,9.39214285714286],
				[1.06372093023256,2.07744186046512,3.09116279069767,4.10511627906977,5.11883720930233,6.13255813953488,7.14627906976744,8.16,9.17372093023256],
				[1.03954545454545,2.03022727272727,3.02090909090909,4.01181818181818,5.0025,5.99318181818182,6.98386363636364,7.97454545454545,8.96522727272727],
				[1.01644444444444,1.98511111111111,2.95377777777778,3.92266666666667,4.89133333333333,5.86,6.82866666666667,7.79733333333333,8.766],
				[0.994347826086957,1.94195652173913,2.8895652173913,3.83739130434783,4.785,5.73260869565217,6.68021739130435,7.62782608695652,8.5754347826087],
				[0.973191489361702,1.90063829787234,2.82808510638298,3.75574468085106,4.6831914893617,5.61063829787234,6.53808510638298,7.46553191489362,8.39297872340426],
				[0.952916666666667,1.86104166666667,2.76916666666667,3.6775,4.585625,5.49375,6.401875,7.31,8.218125],
				[0.933469387755102,1.8230612244898,2.71265306122449,3.60244897959184,4.49204081632653,5.38163265306122,6.27122448979592,7.16081632653061,8.05040816326531],
				[0.9148,1.7866,2.6584,3.5304,4.4022,5.274,6.1458,7.0176,7.8894],
				[0.896862745098039,1.75156862745098,2.60627450980392,3.46117647058824,4.31588235294118,5.17058823529412,6.02529411764706,6.88,7.73470588235294],
				[0.879615384615385,1.71788461538462,2.55615384615385,3.39461538461538,4.23288461538462,5.07115384615385,5.90942307692308,6.74769230769231,7.58596153846154],
				[0.863018867924528,1.68547169811321,2.50792452830189,3.33056603773585,4.15301886792453,4.97547169811321,5.79792452830189,6.62037735849057,7.44283018867925],
				[0.847037037037037,1.65425925925926,2.46148148148148,3.26888888888889,4.07611111111111,4.88333333333333,5.69055555555556,6.49777777777778,7.305],
				[0.831636363636364,1.62418181818182,2.41672727272727,3.20945454545455,4.002,4.79454545454545,5.58709090909091,6.37963636363636,7.17218181818182],
				[0.816785714285714,1.59517857142857,2.37357142857143,3.15214285714286,3.93053571428571,4.70892857142857,5.48732142857143,6.26571428571429,7.04410714285714],
				[0.802456140350877,1.56719298245614,2.3319298245614,3.09684210526316,3.86157894736842,4.62631578947368,5.39105263157895,6.15578947368421,6.92052631578947],
				[0.788620689655172,1.5401724137931,2.29172413793103,3.04344827586207,3.795,4.54655172413793,5.29810344827586,6.04965517241379,6.80120689655172],
				[0.775254237288136,1.51406779661017,2.2528813559322,2.99186440677966,3.7306779661017,4.46949152542373,5.20830508474576,5.9471186440678,6.68593220338983],
				[0.762333333333333,1.48883333333333,2.21533333333333,2.942,3.6685,4.395,5.1215,5.848,6.5745],
				[0.936229508196721,1.83737704918033,2.7383606557377,3.63950819672131,4.54049180327869,5.4416393442623,6.34262295081967,7.24377049180328,8.14475409836066],
				[0.921129032258065,1.80774193548387,2.6941935483871,3.5808064516129,4.46725806451613,5.35387096774194,6.24032258064516,7.12693548387097,8.01338709677419],
				[0.906507936507936,1.77904761904762,2.65142857142857,3.52396825396825,4.39634920634921,5.26888888888889,6.14126984126984,7.01380952380952,7.88619047619048],
				[0.89234375,1.75125,2.61,3.46890625,4.32765625,5.1865625,6.0453125,6.90421875,7.76296875],
				[0.878615384615385,1.72430769230769,2.56984615384615,3.41553846153846,4.26107692307692,5.10676923076923,5.95230769230769,6.798,7.64353846153846],
				[0.86530303030303,1.69818181818182,2.53090909090909,3.36378787878788,4.19651515151515,5.02939393939394,5.86212121212121,6.695,7.52772727272727],
				[0.852388059701493,1.67283582089552,2.49313432835821,3.31358208955224,4.13388059701493,4.95432835820896,5.77462686567164,6.59507462686567,7.41537313432836],
				[0.839852941176471,1.64823529411765,2.45647058823529,3.26485294117647,4.07308823529412,4.88147058823529,5.68970588235294,6.49808823529412,7.30632352941176],
				[0.82768115942029,1.62434782608696,2.42086956521739,3.21753623188406,4.01405797101449,4.81072463768116,5.60724637681159,6.40391304347826,7.2004347826087],
				[0.815857142857143,1.60114285714286,2.38628571428571,3.17157142857143,3.95671428571429,4.742,5.52714285714286,6.31242857142857,7.09757142857143],
				[0.804366197183099,1.57859154929577,2.35267605633803,3.1269014084507,3.90098591549296,4.67521126760563,5.44929577464789,6.22352112676056,6.99760563380282],
				[0.793194444444444,1.55666666666667,2.32,3.08347222222222,3.84680555555556,4.61027777777778,5.37361111111111,6.13708333333333,6.90041666666667],
				[0.782328767123288,1.53534246575342,2.28821917808219,3.04123287671233,3.7941095890411,4.54712328767123,5.3,6.05301369863014,6.8058904109589],
				[0.771756756756757,1.51459459459459,2.2572972972973,3.00013513513513,3.74283783783784,4.48567567567568,5.22837837837838,5.97121621621622,6.71391891891892],
				[0.761466666666667,1.4944,2.2272,2.96013333333333,3.69293333333333,4.42586666666667,5.15866666666667,5.8916,6.6244],
				[0.751447368421053,1.47473684210526,2.19789473684211,2.92118421052632,3.64434210526316,4.36763157894737,5.09078947368421,5.81407894736842,6.53723684210526],
				[0.741688311688312,1.45558441558442,2.16935064935065,2.88324675324675,3.59701298701299,4.31090909090909,5.02467532467532,5.73857142857143,6.45233766233766],
				[0.732179487179487,1.43692307692308,2.14153846153846,2.84628205128205,3.55089743589744,4.25564102564103,4.96025641025641,5.665,6.36961538461538],
				[0.722911392405063,1.41873417721519,2.11443037974684,2.81025316455696,3.50594936708861,4.20177215189873,4.89746835443038,5.59329113924051,6.28898734177215],
				[0.713875,1.401,2.088,2.775125,3.462125,4.14925,4.83625,5.523375,6.210375],
				[0.705061728395062,1.3837037037037,2.06222222222222,2.74086419753086,3.41938271604938,4.09802469135802,4.77654320987654,5.45518518518519,6.1337037037037],
				[0.696463414634146,1.36682926829268,2.03707317073171,2.70743902439024,3.37768292682927,4.0480487804878,4.71829268292683,5.38865853658537,6.05890243902439],
				[0.688072289156627,1.35036144578313,2.01253012048193,2.67481927710843,3.33698795180723,3.99927710843374,4.66144578313253,5.32373493975904,5.98590361445783],
				[0.679880952380952,1.33428571428571,1.98857142857143,2.64297619047619,3.29726190476191,3.95166666666667,4.60595238095238,5.26035714285714,5.91464285714286],
				[0.671882352941176,1.31858823529412,1.96517647058824,2.61188235294118,3.25847058823529,3.90517647058824,4.55176470588235,5.19847058823529,5.84505882352941],
				[0.66406976744186,1.30325581395349,1.94232558139535,2.58151162790698,3.22058139534884,3.85976744186047,4.49883720930233,5.13802325581395,5.77709302325581],
				[0.656436781609195,1.28827586206897,1.92,2.55183908045977,3.1835632183908,3.81540229885057,4.44712643678161,5.07896551724138,5.71068965517241],
				[0.648977272727273,1.27363636363636,1.89818181818182,2.52284090909091,3.14738636363636,3.77204545454545,4.39659090909091,5.02125,5.64579545454545],
				[0.641685393258427,1.25932584269663,1.87685393258427,2.49449438202247,3.11202247191011,3.72966292134831,4.34719101123595,4.96483146067416,5.5823595505618],
				[0.634555555555556,1.24533333333333,1.856,2.46677777777778,3.07744444444444,3.68822222222222,4.29888888888889,4.90966666666667,5.52033333333333]
			],
			premioDiarioMundo: [
				[14.03,25.91,37.79,49.67,61.54,73.42,85.3,97.18,109.06],
				[7.015,12.955,18.895,24.835,30.77,36.71,42.65,48.59,54.53],
				[6.32333333333333,11.9333333333333,17.54,23.15,28.7566666666667,34.3633333333333,39.9733333333333,45.58,51.1866666666667],
				[6.0575,11.5775,17.0975,22.6175,28.1375,33.6575,39.1775,44.6975,50.2175],
				[4.846,9.262,13.678,18.094,22.51,26.926,31.342,35.758,40.174],
				[5.27333333333333,10.1866666666667,15.1016666666667,20.0166666666667,24.9316666666667,29.845,34.76,39.675,44.59],
				[4.52,8.73142857142857,12.9442857142857,17.1571428571429,21.37,25.5814285714286,29.7942857142857,34.0071428571429,38.22],
				[3.955,7.64,11.32625,15.0125,18.69875,22.38375,26.07,29.75625,33.4425],
				[4.04222222222222,7.84555555555556,11.6477777777778,15.4511111111111,19.2544444444444,23.0577777777778,26.8611111111111,30.6633333333333,34.4666666666667],
				[3.638,7.061,10.483,13.906,17.329,20.752,24.175,27.597,31.02],
				[3.30727272727273,6.41909090909091,9.53,12.6418181818182,15.7536363636364,18.8654545454545,21.9772727272727,25.0881818181818,28.2],
				[3.03166666666667,5.88416666666667,8.73583333333333,11.5883333333333,14.4408333333333,17.2933333333333,20.1458333333333,22.9975,25.85],
				[3.08384615384615,6.00153846153846,8.92,11.8384615384615,14.7561538461538,17.6746153846154,20.5930769230769,23.5107692307692,26.4292307692308],
				[2.86357142857143,5.57285714285714,8.28285714285714,10.9928571428571,13.7021428571429,16.4121428571429,19.1221428571429,21.8314285714286,24.5414285714286],
				[2.67266666666667,5.20133333333333,7.73066666666667,10.26,12.7886666666667,15.318,17.8473333333333,20.376,22.9053333333333],
				[2.505625,4.87625,7.2475,9.61875,11.989375,14.360625,16.731875,19.1025,21.47375],
				[2.87764705882353,5.62941176470588,8.38058823529412,11.1317647058824,13.8835294117647,16.6347058823529,19.3858823529412,22.1376470588235,24.8888235294118],
				[2.71777777777778,5.31666666666667,7.915,10.5133333333333,13.1122222222222,15.7105555555556,18.3088888888889,20.9077777777778,23.5061111111111],
				[2.57473684210526,5.03684210526316,7.49842105263158,9.96,12.4221052631579,14.8836842105263,17.3452631578947,19.8073684210526,22.2689473684211],
				[2.446,4.785,7.1235,9.462,11.801,14.1395,16.478,18.817,21.1555],
				[2.32952380952381,4.55714285714286,6.78428571428571,9.01142857142857,11.2390476190476,13.4661904761905,15.6933333333333,17.9209523809524,20.1480952380952],
				[2.22363636363636,4.35,6.47590909090909,8.60181818181818,10.7281818181818,12.8540909090909,14.98,17.1063636363636,19.2322727272727],
				[2.36739130434783,4.64130434782609,6.91521739130435,9.18913043478261,11.4626086956522,13.7365217391304,16.0104347826087,18.284347826087,20.5582608695652],
				[2.26875,4.44791666666667,6.62708333333333,8.80625,10.985,13.1641666666667,15.3433333333333,17.5225,19.7016666666667],
				[2.178,4.27,6.362,8.454,10.5456,12.6376,14.7296,16.8216,18.9136],
				[2.09423076923077,4.10576923076923,6.11730769230769,8.12884615384615,10.14,12.1515384615385,14.1630769230769,16.1746153846154,18.1861538461538],
				[2.01666666666667,3.9537037037037,5.89074074074074,7.82777777777778,9.76444444444444,11.7014814814815,13.6385185185185,15.5755555555556,17.5125925925926],
				[1.94464285714286,3.8125,5.68035714285714,7.54821428571429,9.41571428571429,11.2835714285714,13.1514285714286,15.0192857142857,16.8871428571429],
				[1.87758620689655,3.68103448275862,5.48448275862069,7.28793103448276,9.09103448275862,10.8944827586207,12.6979310344828,14.5013793103448,16.3048275862069],
				[1.815,3.55833333333333,5.30166666666667,7.045,8.788,10.5313333333333,12.2746666666667,14.018,15.7613333333333],
				[1.75645161290323,3.44354838709677,5.13064516129032,6.81774193548387,8.50451612903226,10.1916129032258,11.8787096774194,13.5658064516129,15.2529032258065],
				[2.074375,4.0815625,6.08875,8.09625,10.1034375,12.110625,14.1178125,16.125,18.1321875],
				[2.01151515151515,3.95787878787879,5.90424242424242,7.85090909090909,9.79727272727273,11.7436363636364,13.69,15.6363636363636,17.5827272727273],
				[1.95235294117647,3.84147058823529,5.73058823529412,7.62,9.50911764705882,11.3982352941176,13.2873529411765,15.1764705882353,17.0655882352941],
				[1.89657142857143,3.73171428571429,5.56685714285714,7.40228571428571,9.23742857142857,11.0725714285714,12.9077142857143,14.7428571428571,16.578],
				[1.84388888888889,3.62805555555556,5.41222222222222,7.19666666666667,8.98083333333333,10.765,12.5491666666667,14.3333333333333,16.1175],
				[1.79405405405405,3.53,5.26594594594595,7.00216216216216,8.73810810810811,10.4740540540541,12.21,13.9459459459459,15.6818918918919],
				[1.74684210526316,3.43710526315789,5.12736842105263,6.8178947368421,8.50815789473684,10.1984210526316,11.8886842105263,13.5789473684211,15.2692105263158],
				[1.70205128205128,3.34897435897436,4.99589743589744,6.64307692307692,8.29,9.93692307692308,11.5838461538462,13.2307692307692,14.8776923076923],
				[1.6595,3.26525,4.871,6.477,8.08275,9.6885,11.29425,12.9,14.50575],
				[1.6190243902439,3.18560975609756,4.75219512195122,6.3190243902439,7.88560975609756,9.45219512195122,11.0187804878049,12.5853658536585,14.1519512195122],
				[1.58047619047619,3.10976190476191,4.63904761904762,6.16857142857143,7.69785714285714,9.22714285714286,10.7564285714286,12.2857142857143,13.815],
				[1.54372093023256,3.03744186046512,4.53116279069767,6.02511627906977,7.51883720930233,9.01255813953488,10.5062790697674,12,13.4937209302326],
				[1.50863636363636,2.96840909090909,4.42818181818182,5.88818181818182,7.34795454545455,8.80772727272727,10.2675,11.7272727272727,13.1870454545455],
				[1.47511111111111,2.90244444444444,4.32977777777778,5.75733333333333,7.18466666666667,8.612,10.0393333333333,11.4666666666667,12.894],
				[1.44304347826087,2.83934782608696,4.23565217391304,5.63217391304348,7.02847826086956,8.42478260869565,9.82108695652174,11.2173913043478,12.6136956521739],
				[1.41234042553191,2.77893617021277,4.14553191489362,5.51234042553191,6.87893617021277,8.24553191489362,9.61212765957447,10.9787234042553,12.3453191489362],
				[1.38291666666667,2.72104166666667,4.05916666666667,5.3975,6.735625,8.07375,9.411875,10.75,12.088125],
				[1.35469387755102,2.66551020408163,3.97632653061225,5.28734693877551,6.59816326530612,7.90897959183673,9.21979591836735,10.530612244898,11.8414285714286],
				[1.3276,2.6122,3.8968,5.1816,6.4662,7.7508,9.0354,10.32,11.6046],
				[1.30156862745098,2.56098039215686,3.82039215686275,5.08,6.33941176470588,7.59882352941177,8.85823529411765,10.1176470588235,11.3770588235294],
				[1.27653846153846,2.51173076923077,3.74692307692308,4.98230769230769,6.2175,7.45269230769231,8.68788461538461,9.92307692307692,11.1582692307692],
				[1.25245283018868,2.46433962264151,3.67622641509434,4.88830188679245,6.10018867924528,7.31207547169811,8.52396226415094,9.73584905660377,10.9477358490566],
				[1.22925925925926,2.4187037037037,3.60814814814815,4.79777777777778,5.98722222222222,7.17666666666667,8.36611111111111,9.55555555555556,10.745],
				[1.20690909090909,2.37472727272727,3.54254545454545,4.71054545454545,5.87836363636364,7.04618181818182,8.214,9.38181818181818,10.5496363636364],
				[1.18535714285714,2.33232142857143,3.47928571428571,4.62642857142857,5.77339285714286,6.92035714285714,8.06732142857143,9.21428571428571,10.36125],
				[1.16456140350877,2.29140350877193,3.41824561403509,4.54526315789474,5.67210526315789,6.79894736842105,7.92578947368421,9.05263157894737,10.1794736842105],
				[1.14448275862069,2.25189655172414,3.35931034482759,4.46689655172414,5.57431034482759,6.68172413793103,7.78913793103448,8.89655172413793,10.0039655172414],
				[1.12508474576271,2.21372881355932,3.30237288135593,4.39118644067797,5.47983050847458,6.56847457627119,7.6571186440678,8.74576271186441,9.83440677966102],
				[1.10633333333333,2.17683333333333,3.24733333333333,4.318,5.3885,6.459,7.5295,8.6,9.6705],
				[1.29540983606557,2.55573770491803,3.81590163934426,5.07606557377049,6.33639344262295,7.59655737704918,8.85672131147541,10.1170491803279,11.3772131147541],
				[1.27451612903226,2.51451612903226,3.75435483870968,4.9941935483871,6.2341935483871,7.47403225806452,8.71387096774193,9.95387096774193,11.1937096774194],
				[1.25428571428571,2.47460317460317,3.69476190476191,4.91492063492063,6.13523809523809,7.35539682539683,8.57555555555555,9.79587301587302,11.0160317460317],
				[1.2346875,2.4359375,3.63703125,4.838125,6.039375,7.24046875,8.4415625,9.6428125,10.84390625],
				[1.21569230769231,2.39846153846154,3.58107692307692,4.76369230769231,5.94646153846154,7.12907692307692,8.31169230769231,9.49446153846154,10.6770769230769],
				[1.19727272727273,2.36212121212121,3.52681818181818,4.69151515151515,5.85636363636364,7.02106060606061,8.18575757575758,9.35060606060606,10.515303030303],
				[1.17940298507463,2.32686567164179,3.47417910447761,4.62149253731343,5.7689552238806,6.91626865671642,8.06358208955224,9.2110447761194,10.3583582089552],
				[1.16205882352941,2.29264705882353,3.42308823529412,4.55352941176471,5.68411764705882,6.81455882352941,7.945,9.07558823529412,10.2060294117647],
				[1.14521739130435,2.25942028985507,3.37347826086957,4.48753623188406,5.60173913043478,6.71579710144927,7.82985507246377,8.94405797101449,10.058115942029],
				[1.12885714285714,2.22714285714286,3.32528571428571,4.42342857142857,5.52171428571429,6.61985714285714,7.718,8.81628571428571,9.91442857142857],
				[1.11295774647887,2.19577464788732,3.27845070422535,4.36112676056338,5.44394366197183,6.52661971830986,7.60929577464789,8.69211267605634,9.77478873239437],
				[1.0975,2.16527777777778,3.23291666666667,4.30055555555556,5.36833333333333,6.43597222222222,7.50361111111111,8.57138888888889,9.63902777777778],
				[1.08246575342466,2.13561643835616,3.1886301369863,4.24164383561644,5.29479452054794,6.34780821917808,7.40082191780822,8.45397260273973,9.50698630136986],
				[1.06783783783784,2.10675675675676,3.14554054054054,4.18432432432432,5.22324324324324,6.26202702702703,7.30081081081081,8.33972972972973,9.37851351351351],
				[1.0536,2.07866666666667,3.1036,4.12853333333333,5.1536,6.17853333333333,7.20346666666667,8.22853333333333,9.25346666666667],
				[1.03973684210526,2.05131578947368,3.06276315789474,4.07421052631579,5.08578947368421,6.09723684210526,7.10868421052632,8.12026315789474,9.13171052631579],
				[1.02623376623377,2.02467532467532,3.02298701298701,4.0212987012987,5.01974025974026,6.01805194805195,7.01636363636364,8.0148051948052,9.01311688311688],
				[1.01307692307692,1.99871794871795,2.98423076923077,3.96974358974359,4.95538461538461,5.94089743589744,6.92641025641026,7.91205128205128,8.8975641025641],
				[1.00025316455696,1.97341772151899,2.94645569620253,3.91949367088608,4.8926582278481,5.86569620253165,6.83873417721519,7.81189873417722,8.78493670886076],
				[0.98775,1.94875,2.909625,3.8705,4.8315,5.792375,6.75325,7.71425,8.675125],
				[0.975555555555556,1.92469135802469,2.8737037037037,3.82271604938272,4.77185185185185,5.72086419753086,6.66987654320988,7.61901234567901,8.56802469135802],
				[0.963658536585366,1.90121951219512,2.83865853658537,3.77609756097561,4.71365853658537,5.65109756097561,6.58853658536585,7.52609756097561,8.46353658536585],
				[0.952048192771084,1.87831325301205,2.8044578313253,3.73060240963855,4.65686746987952,5.58301204819277,6.50915662650602,7.43542168674699,8.36156626506024],
				[0.940714285714286,1.85595238095238,2.77107142857143,3.68619047619048,4.60142857142857,5.51654761904762,6.43166666666667,7.34690476190476,8.26202380952381],
				[0.929647058823529,1.83411764705882,2.73847058823529,3.64282352941176,4.54729411764706,5.45164705882353,6.356,7.26047058823529,8.16482352941176],
				[0.918837209302325,1.81279069767442,2.70662790697674,3.60046511627907,4.49441860465116,5.38825581395349,6.28209302325581,7.17604651162791,8.06988372093023],
				[0.908275862068965,1.79195402298851,2.67551724137931,3.55908045977011,4.44275862068965,5.32632183908046,6.20988505747126,7.0935632183908,7.97712643678161],
				[0.897954545454545,1.77159090909091,2.64511363636364,3.51863636363636,4.39227272727273,5.26579545454545,6.13931818181818,7.01295454545455,7.88647727272727],
				[0.887865168539326,1.75168539325843,2.61539325842697,3.47910112359551,4.34292134831461,5.20662921348315,6.07033707865169,6.93415730337079,7.79786516853933],
				[0.878,1.73222222222222,2.58633333333333,3.44044444444444,4.29466666666667,5.14877777777778,6.00288888888889,6.85711111111111,7.71122222222222]
				]
		}
	},
	mounted() {

	},
	methods: {
		// Actions for the minus buttons in the simulator
		setpessoasValue: function(event, eventTarget) {
			this.pessoasValue = 1;
			if (parseInt(eventTarget) < 1) {
				this.pessoasValue = 0;
			}
			if(eventTarget === null) eventTarget = '1';
			let value = Math.max(1, Math.min(parseInt(eventTarget), 9)) || 1;
			this.pessoasValue = value;
			this.activePlus();
			this.activeMinus();
		},
		detalhe: function() {
			dataLayer.push({'event': 'eventPage', 'eventAction': 'Click', 'eventCategory': 'Simulador - Seguro de Viagem', 'eventLabel': 'Ver Detalhes da Simulação'});
		},
		setdiasValue: function(event, eventTarget) {
			this.diasValue = 1;
			if (parseInt(eventTarget) < 1) {
				this.diasValue = 0;
			}
			if(eventTarget === null) eventTarget = '1';
			let value = Math.max(1, Math.min(parseInt(eventTarget), 90)) || 1;
			this.diasValue = value;
			this.activePlus();
			this.activeMinus();
		},
		minusButtonClick: function(event) {
			if (event) {
				let grandParentId = event.target.parentNode.parentNode.id
				// Just decrement the value by 1 unit with a cap of 0
				if(grandParentId == "simulador-pessoas" && this.pessoasValue > 1) {
					this.pessoasValue--;
				}
				else if(grandParentId == "simulador-dias" && this.diasValue > 1) {
					this.diasValue--;
				}
			}

			this.activeMinus();
		},
		// Actions for the plus buttons in the simulator
		plusButtonClick: function(event) {
			if (event) {
				let grandParentId = event.target.parentNode.parentNode.id
				// Just increment the value by 1 unit with a cap of 9
				if(grandParentId == "simulador-pessoas" && this.pessoasValue < 9) {
					this.pessoasValue++;
				}
				else if(grandParentId == "simulador-dias" && this.diasValue < 90) {
					this.diasValue++;
				}

				this.activePlus();
			}
		},
		activePlus: function() {
				if(this.pessoasValue == 9 ) { this.isButtonPlusPessoasActive = false; }
				if(this.pessoasValue > 1) { this.isButtonMinusPessoasActive = true;}
				if(this.diasValue == 90) { this.isButtonPlusDiasActive = false; }
				if(this.diasValue > 1) { this.isButtonMinusDiasActive = true;}
		},
		activeMinus: function() {
				if(this.pessoasValue == 1) { this.isButtonMinusPessoasActive = false; }
				if(this.pessoasValue < 9) { this.isButtonPlusPessoasActive = true; }
				if(this.diasValue == 1) { this.isButtonMinusDiasActive = false; }
				if(this.diasValue < 90) { this.isButtonPlusDiasActive = true; }
		},
		// Actions for the submit in the simulator
		simularClick: function() {
			console.log('simular');
			let indexDiasValue = (parseInt(this.diasValue) - 1);
			let valueFromTable = 0.0;
			let selectedArrayPremio = null;
			let selectArrayTotal = null;

			if(this.zonePicked == "europa") {
				this.resultAreaValue = "Europa e Mediterrâneo";
				selectedArrayPremio = this.premioDiarioEuropa;
				selectArrayTotal = this.premioTotalEuropa;
			}
			else if(this.zonePicked == "mundo") {
				this.resultAreaValue = "Resto do Mundo";
				selectedArrayPremio = this.premioDiarioMundo;
				selectArrayTotal = this.premioTotalMundo
			}

			// Validation to prevent index out of bounds error if the array is incomplete
			// The values limit is made on the button itself
			if(indexDiasValue < selectedArrayPremio.length) {
				valueFromTable = selectedArrayPremio[(parseInt(this.diasValue) - 1)][(parseInt(this.pessoasValue) - 1)];
				this.resultTotal = selectArrayTotal[(parseInt(this.diasValue) - 1)][(parseInt(this.pessoasValue) - 1)];
			}
			this.resultTotal = this.resultTotal.toFixed(2);
			this.resultValue = valueFromTable.toFixed(2);
			// Set the values in the store so we can get them in the where we want them.
			mutations.setValues(this.zonePicked, this.pessoasValue, this.diasValue, this.resultValue);
			// Emit an event when we have the values
			EventBus.$emit('simulation-values-set');

			this.isSimulation = false;
			dataLayer.push({'event': 'eventPage', 'eventAction': 'Click', 'eventCategory': 'Simulador - Seguro de Viagem', 'eventLabel': 'Simular'});
			dataLayer.push({'event': 'eventPage', 'eventAction': 'Detalhe', 'eventCategory': 'Simulador - Seguro de Viagem', 'eventLabel': 'Destino_'+this.resultAreaValue+'; Pessoas Seguras_'+this.pessoasValue+'; Dias de viagem_'+this.diasValue+'; O teu seguro viagem_'+this.resultValue+''});
		},
		gplay: function() {
			dataLayer.push({'event': 'eventPage', 'eventAction': 'Click', 'eventCategory': 'Just in Case - App Stores', 'eventLabel': 'Google Play'});
		},
		astore: function() {
			dataLayer.push({'event': 'eventPage', 'eventAction': 'Click', 'eventCategory': 'Just in Case - App Stores', 'eventLabel': 'App Store'});
		},
		linkSms: function() {
			
		},
		backToSimulation: function() {
			dataLayer.push({'event': 'eventPage', 'eventAction': 'Click', 'eventCategory': 'Simulador - Seguro de Viagem', 'eventLabel': 'Simular Novamente'});
			this.isSimulation = true;
			// Emit an event when we have the values
			EventBus.$emit('hide-simulation-link');
		}
	},		
	computed: {
		isIos() {
			if (window.innerWidth < 768) {
				if(navigator.userAgent.toLowerCase().indexOf("iphone") > -1){
					return 1;
				}
				return 0;
			}
			return 1;
		},
		isAndroid() {
			if (window.innerWidth < 768) {
				if(navigator.userAgent.toLowerCase().indexOf("android") > -1){
					return 1;
				}
				return 0;
			}
			return 1;
		},
		detalheSimulUrl() {
			console.log(`{name:'detalhe',params: { zone:'${this.zonePicked}', days:${this.diasValue}, value:${this.resultTotal}, people:${this.pessoasValue}, example:false }}`);
			return ``;
			//return '/detalhe-simulacao/' + this.zonePicked + '/' + this.pessoasValue + '/' +  this.diasValue + '/' +  this.resultTotal + '/false';
		}
	}
}
</script>

<style lang="scss">
@import '@/assets/sass/_variables.scss';

.pretty {
	font-size: 22px;
	white-space: unset;
}

.pretty.p-round .state label:before, .pretty.p-round .state label:after {
	margin-top: 5px;
	border-color: $color-gray-radios;
}

.pretty input:checked ~ .state.p-primary .icon {
	margin-top: 5px;
}

input[type=number] {
	max-width: 24px;
    border: none;
    /* padding: 0px; */
    padding-top: 5px;
    margin: 0px;
    text-align: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.pretty.p-icon input:checked ~ .state label:before {
	border-color: transparent !important;
}


.seguro {
	background-color: $color-main;
	color: $color-white;
	margin-top: 150px;

	@media (min-width: 768px) {
		margin-top: 180px;
	}
	
	@media (min-width: $xlarge) {
		.seguro-wrapper, .payments {
			padding-left: 80px;
		}
	}

	@media (min-width: 1280px) {
		.seguro-wrapper, .payments {
			padding-left: 150px;
		}
	}


	.top-panel {
		width: 100%;
		text-align: center;
		color: black;
		margin-top: -125px;

		@media (min-width: $medium) {
			margin-top: -150px;
		}

		span {
			display: block;
			font-size: 20px;
			line-height: 24px;
			letter-spacing: 0.17px;
			font-weight: bold;
		}

		.arrow {
			border: solid $color-main;
			border-width: 0 2px 2px 0;
			display: inline-block;
			padding: 8px;

			transform: rotate(45deg);
  			-webkit-transform: rotate(45deg);
		}
	}

	.image {
		width: 175px;
		margin: 15px 0;
		@media (min-width: 768px) {
			width: 210px;
		}
		img {
			width: 100%;
		}
	}

	h3 {
		color: $color-white;
	}

	.seguro-wrapper {
		margin: 30px 0;

		@media (min-width: $medium) {
			margin: 70px 0;
		}

		.simulator-title {
			padding: 60px 24px 0;
			margin-bottom: 30px;
			font-size: 24px;
  			font-weight: bold;
			letter-spacing: 0.2px;
			text-align: center;
			color: #171717;
		}

		.top {
			padding: 30px 15px;
			span {
				display: block;
				color: $color-gray;
				font-size: 16px;
				line-height: 20px;
				letter-spacing: 0.2px;

				@media (min-width: $large) {
					font-size: 18px;
				}
			}
		}

		.separator {
			height: 1px;
			background-color: $color-black-transp;
			width: 100%;
		}

		.simulador-wrapper {
				padding: 30px 15px;

				span {
					color: $color-black;
					margin-left: 9px;
					font-weight: 700;
					letter-spacing: 0.13px;

					@media (min-width: $medium) {
						font-size: 16px;
					}

					@media (min-width: $large) {
						font-size: 20px;
					}
				}

				.destino {
					.help {
						position: absolute;
						left: calc(100% - 20px);
						top: 2px;
						width: 20px;
						height: 20px;
						border: 1px solid $color-gray-radios;
						border-radius: 10px;
						cursor: pointer;

						span {
							display: block;
							margin-left: 3px;
							margin-top: -7px;
							font-size: 12px;
							
							i {
								color: $color-gray-radios;
							}
						}
					}
				}
		}

		.button-simulador-wrapper {
			padding: 30px 60px;

			.button-simulador {
				cursor: pointer;
				width: 100%;
				height: 40px;
				border-radius: 20px;
				color: $color-white;
				text-align: center;
				background-color: $color-main;

				@media (min-width: $medium) {
					width: 230px;
					margin: 0 auto;
					height: 60px;
					border-radius: 38.5px;
				}

				span {
					line-height: 40px;
					@media (min-width: $medium) {
						line-height: 60px;
					}
				}
			}
		}

		.simulador-container {
			display: none;
			background-color: $color-white;
			border-radius: 10px;
			box-shadow: 3px 3px 12px 0 rgba(0, 0, 0, 0.1), 0 3px 12px 0 rgba(0, 0, 0, 0.06);

			@media (min-width: $medium) {
				max-width: 540px;
				margin: 0 auto;
			}

			&.show {
				display: block;
			}

			.simulador-wrapper {

				.radio-button-area {
					position: relative;
					display: block;
					margin-left: 15px;

					&.first {
						margin-top: 10px;
						margin-bottom: 15px;
					}
					&:last-child {
						margin-bottom: 30px;
					}
				}

				.radio-text {
					font-size: 16px;
					line-height: 24px;
					font-weight: 200;
					display: block;
					margin-top: -10px;

					@media (min-width: $medium) {
						font-size: 20px
					}
				}

				.pessoas-seguras {
					margin-bottom: 30px;
				}

				.simulador-buttons {
					.button {
						position: relative;
						border-radius: 16px;
						padding: 10px;
						height: 32px;
						width: 32px;

						span {
							position: absolute;
							top: 0;
							left: 0;
							display: block;
							font-size: 16px;
							font-weight: 200;
							width: 100%;
							margin: 0 auto;
							text-align: center;
						}
					}

					.minus, .plus {
						background-color: $color-gray-bg;
						cursor: pointer;
						
						span {
							color: $color-gray-button-text;
							font-size: 23px;
							font-weight: 200;
						}

						&.active {
							background-color: $color-blue-button;
							span {
								color: $color-white;
							}
						}
					}

					.value {
						width: 32px;
						margin: 0 10px;
						span {
							font-size: 16px;
							font-weight: 200;
							line-height: 32px;
							color: $color-black;
							margin-left: 0;

							@media (min-width: $large) {
								font-size: 20px;
							}
						}
					}
				}
			}
		}

		.result-container {
			display: none;
			background-color: $color-white;
			border-radius: 10px;
			box-shadow: 3px 3px 12px 0 rgba(0, 0, 0, 0.1), 0 3px 12px 0 rgba(0, 0, 0, 0.06);

			@media (min-width: $medium) {
				max-width: 540px;
				margin: 0 auto;
			}

			&.show {
				display: block;
			}

			.result-value {
				span {
					color: $color-main;

					@media (min-width: $large) {
						font-size: 24px;
					}

					@media (min-width: $xlarge) {
						font-size: 28px;
					}
				}
			}

			.simulador-wrapper {
				> div {
					margin-bottom: 15px;
				}

				span {
					margin-right: 9px;

					@media (min-width: $medium) {
						font-size: 14px;
					}

					@media (min-width: $large) {
						font-size: 20px;
					}
				}

				.result-area-text {
					font-size: 16px;
					line-height: 32px;
					font-weight: 200;

					@media (min-width: $large) {
						font-size: 20px;
					}

				}
			}

			.simulador-values {
				.value {
					span {
						font-size: 16px;
						font-weight: 200;
						line-height: 32px;
						color: $color-black;
						margin-left: 0;

						@media (min-width: $large) {
							font-size: 20px;
						}
					}
				}
			}

			.result-download-text {
				padding: 25px 15px 0 15px;

				span {
					display: block;
					color: $color-black;
					font-size: 16px;
					font-weight: 200;
					line-height: 20px;
					letter-spacing: 0.2px;

					@media (min-width: $large) {
						font-size: 18px;
					}
				}
			}

			.button-simulador-wrapper {
				padding: 20px 60px 27px 60px;

				.link-simulador {
					width: 100%;
					text-align: center;
					margin-top: 16px;
					cursor: pointer;
					color: $color-main;

					span {
						color: $color-main;
						text-decoration: underline;
						font-size: 16px;
						font-weight: 700;
						line-height: 24px;
						letter-spacing: 0.2px;
					}
				}
			}
		}
	}

	.info-wrapper {
		margin-bottom: 20px;
		@media (min-width: 768px) {
			max-width: 444px;
			margin: 100px auto;
			padding-right: 0px;
		}
	}

	h3 {
		font-size: 3.4rem;

		// Exception
		@media (min-width: 340px) {
			font-size: 3.6rem;
		}
		@media (min-width: 768px) {
			font-size: 4rem;
		}

		span {
			vertical-align: top;
			font-size: 20px;
			letter-spacing: 0.2px;
		}


	}
	h4 {
		color: white;
		padding-top: 5px;
	}
	a {
		color: white;
		text-decoration: underline;
	}

	.below-title {
		font-weight: 200;
		font-size: 20px;
		line-height: 26px;
		letter-spacing: 0.17px;
		padding-top: 20px;
		padding-bottom: 30px;
	}

	hr {
		border: 0;
		padding: 0;
		margin: 0 0 30px 0;
		width: 31px;
		height: 1px;
		border-radius: 4px;
		background-color: $color-white;
		
	}

	.checked-list-title {
		display: block;
		font-size: 20px;
		font-weight: 700;
		padding-bottom: 15px;
		letter-spacing: inherit;
		/* @include max-screen($large) {
			font-size: 22px;
		} */
		@media (min-width: 768px) {
			font-size: 24px;
		}

	}
	.checked-list {
		li {
			display: block;
			line-height: 24px;
			font-size: 18px;
			font-weight: 300;
			padding-left: 30px;
			padding-bottom: 8px;
			letter-spacing: 0.2px;
			position: relative;
			@media (min-width: 768px) {
				font-size: 20px;
			}

			&:before {
				content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='11'%3E%3Cpath d='M7.05 10.547l8.467-7.914a1.47 1.47 0 0 0 0-2.18 1.73 1.73 0 0 0-2.334 0l-7.299 6.82-3.067-2.865a1.733 1.733 0 0 0-2.334 0 1.475 1.475 0 0 0 0 2.184l4.233 3.955c.31.29.73.453 1.168.453a1.71 1.71 0 0 0 1.166-.453z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/svg%3E");
				display: block;
				position: absolute;
				left: 0;
				top: 0;
				height: 11px;
				width: 16px;
			}
		}
		&:first-of-type {
			padding-bottom: 25px;
		}
	}
	.sub-text {
		font-weight: 500;
	}
	.ref-panel {
		display: flex;
		flex-direction: column;
		span {
			font-size: 1rem;
			font-weight: 700;
			line-height: 1.6rem;
			letter-spacing: 0.05rem;
			padding-bottom: 3.5rem;

			/* @include max-screen($large) {
				font-size: 10px;
				line-height: 16px;
			} */
		}
		ul {
			padding-bottom: 1rem;
			li {
				a {
					display: block;
					font-size: 1.2rem;
					font-weight: 500;
					line-height: 1.6rem;
					letter-spacing: 0.02rem;
					text-decoration: underline;

					/* @include max-screen($large) {
						font-size: 12px;
						line-height: 16px;
					} */
				}

				display: block;
				padding-left: 3.5rem;
				line-height: 2rem;
				padding-bottom: 2.5rem;
				position: relative;

				&:before {
					content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20'%3E%3Cpath d='M16.306 9.167c.2 0 .36.186.36.416 0 .23-.16.417-.36.417H7.86c-.199 0-.36-.187-.36-.417 0-.23.161-.416.36-.416h8.446zm0 2.5c.2 0 .36.186.36.416 0 .23-.16.417-.36.417H7.86c-.199 0-.36-.187-.36-.417 0-.23.161-.416.36-.416h8.446zm-3.349 2.5c.208 0 .376.186.376.416 0 .23-.168.417-.376.417h-5.08c-.209 0-.377-.187-.377-.417 0-.23.168-.416.376-.416h5.081zm6.206-6.71a.407.407 0 0 0-.092-.166l-6.3-6.303a.407.407 0 0 0-.08-.057.298.298 0 0 0-.15-.098H5.783a.367.367 0 0 0-.366.367v1.146H1.2a.367.367 0 0 0-.367.366V18.8c0 .203.164.367.367.367h13.014a.367.367 0 0 0 .367-.367v-1.146h4.216a.367.367 0 0 0 .366-.366v-9.74a.304.304 0 0 0 0-.092zM12.5 2.5L15.006 5 17.5 7.5h-5v-5zm1.667 15.833h-12.5v-15h3.908v2.24H3.528a.365.365 0 0 0-.372.357c0 .198.166.359.372.359h2.047v1.79H3.528a.365.365 0 0 0-.372.359c0 .198.166.358.372.358h2.047v1.791H3.528a.365.365 0 0 0-.372.358c0 .198.166.358.372.358h2.047v1.97H3.528a.365.365 0 0 0-.372.359c0 .198.166.358.372.358h2.047v1.791H3.528a.365.365 0 0 0-.372.358c0 .198.166.359.372.359h2.047v.694c0 .197.167.358.373.358h8.19l.029.783zm-8.334-1.666v-15h6.058v2.238H7.699a.366.366 0 0 0-.373.359c0 .198.167.358.373.358h4.192v1.79H7.699a.366.366 0 0 0-.373.36c0 .197.167.357.373.357h4.192v.342a.372.372 0 0 0 .379.358h6.063v8.838h-12.5z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/svg%3E");
					display: block;
					position: absolute;
					left: 0;
					top: 0;
					height: 20px;
					width: 20px;
				}
			}
		}
		.note {
			font-size: 1.2rem;
			font-weight: 500;
			line-height: 1.6rem;
			letter-spacing: 0.02rem;

			/* @include max-screen($large) {
				font-size: 12px;
				line-height: 16px;
			} */
		}
	}

	.splash-wrapper {

		@media (min-width: $medium) {
			margin-bottom: 20px;

			display: flex;
			align-items: center;
			flex-direction: row;
		}

		@media (min-width: $large) {
			margin-bottom: 60px;
		}

		.payments {
			margin: 10px 0 30px 0;

			img {
				max-width: 250px;
			}
		}

		.apps-panel {
			margin: 10px 0 30px 0;

			a {
				display: block;
				height: 100%;
			}

			img {
				height: auto;
			}
		}
	}

	.bottom-panel {
		width: 100%;
		height: 60px;
		background-color: $color-main;
		background: url('../assets/img/wave_02@2x.png');
		background-size: 100%;
		background-repeat: no-repeat;
		background-position: center bottom;

		@media (min-width: $medium) {
			height: 90px;
			background-size: 100% 100%;
		}

		@media (min-width: $large) {
			height: 112px;
		}

		@media (min-width: $xxlarge) {
			height: 160px;
		}
	}
}
</style>