<template>
<section class="faqs" id="faq">
	<div class="container">
		<div class="row" id="faq">
			<div class="col-12 text-center">
				<div class="content-wrapper">
					<h3>Perguntas frequentes</h3>
					<div class="tab-wrapper">
						<ul class="row d-flex flex-row justify-content-between justify-content-md-center">
							<li v-on:click="tab = 1" class="col-4">
								<div :class="['item-wrapper', tab === 1 ? 'active' : null ]">
									<div v-if=" tab === 1" class="image mx-auto"><img src="../assets/img/icon-app-active.svg" alt="App"></div>
									<div v-else class="image mx-auto"><img src="../assets/img/icon-app.svg" alt="App"></div>
									<span>Aplicação</span>
								</div>
							</li>
							<li v-on:click="tab = 2" class="col-4">
								<div :class="['item-wrapper', tab === 2 ? 'active' : null ]">
									<div v-if=" tab === 2" class="image mx-auto"><img src="../assets/img/icon-insurance-active.svg" alt="Insurance"></div>
									<div v-else class="image mx-auto"><img src="../assets/img/icon-insurance.svg" alt="Insurance"></div>
									<span>Seguro</span>
								</div>
							</li>
							<li v-on:click="tab = 3" class="col-4">
								<div :class="['item-wrapper', tab === 3 ? 'active' : null ]">
									<div v-if=" tab === 3" class="image mx-auto"><img src="../assets/img/icon-assistance-active.svg" alt="Phones"></div>
									<div v-else class="image mx-auto"><img src="../assets/img/icon-assistance.svg" alt="Phones"></div>
									<span>Assistência</span>
								</div>
							</li>
						</ul>
						<div class="tab-content" id="tab-content">
							<div v-show="tab === 1" class="content">
								<Accordion v-bind:accordionContent="accordionContent"></Accordion>
							</div>
							<div v-show="tab === 2" class="content">
								<Accordion v-bind:accordionContent="accordionContent2"></Accordion>
							</div>
							<div v-show="tab === 3" class="content">
								<Accordion v-bind:accordionContent="accordionContent3"></Accordion>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="bottom-panel"></div>
	</section>
</template>

<script>
import Accordion from "@/components/Accordion.vue";

export default {
	name: 'Faq',
	components: {
		Accordion
	},
	data() {
		return {
			accordionContent: [
				{ title: 'Como funciona a app Just in Case?', content: 'É muito simples, para poderes utilizar todas as funcionalidades da app, só tens que fazer o teu registo e criar a tua primeira viagem, indicando o destino e a duração.' },
				{ title: 'Como posso fazer o registo?', content: 'Depois de fazer o download, só precisas de inserir o teu número de telemóvel e o código de confirmação recebido via SMS, e aceitar as Condições Gerais de Utilização e a nossa Política de Privacidade e Proteção de Dados Pessoais.' },
				{ title: 'Não estou a conseguir fazer o registo. O que devo fazer?', content: 'Certifica-te de que tens uma ligação de dados ativa. Se mesmo assim não estás a conseguir fazer o registo, contacta-nos através do número +351 217 948 780 para te podermos ajudar.' },
			],
			accordionContent2: [
				{ title: 'Quem é que pode contratar um seguro de viagem Just in Case?', content: 'Todas as pessoas com idades entre os 18 e os 74 anos, residentes em Portugal e com um Número de Identificação Fiscal Português.' },
				{ title: 'Posso fazer seguro para mais do que uma pessoa?', content: 'Sim, é possível subscrever o seguro Just in Case para mais do que uma pessoa, desde que todas pertençam ao teu agregado familiar. Se vais viajar com outros familiares que não pertencem ao teu agregado familiar ou com amigos, cada um terá que fazer o download e o registo na aplicação para contratar o seu próprio seguro.' },
				{ title: 'Posso contratar um seguro em qualquer altura da minha viagem?', content: 'Para contratar o teu seguro de viagem Just in Case tens que estar em território português e a tua viagem ainda não pode ter começado. O teu seguro ficará ativo a partir da data de início da tua viagem.' },
				{ title: 'Qual a duração máxima da viagem que posso segurar?', content: 'Podes contratar o seguro de viagem Just in Case para viagens cuja duração não exceda 90 dias consecutivos.' },
				{ title: 'Como é que posso pagar o seguro que contratei?', content: 'Podes pagar o teu seguro de Viagem Just in Case diretamente na app, através de MB Way ou cartão de crédito.' },
				{ title: 'A partir de que momento estou protegido pelo seguro?', content: 'O seguro fica ativo a partir das 0h da data de início da tua viagem, desde que o pagamento do prémio tenha sido efetuado até essa data. Caso subscrevas o seguro no dia em que vais viajar, o seguro fica ativo a partir da hora em que o subscreveres. Podes consultar a data e hora de início na Proposta de Seguro.' },
				{ title: 'O que é a franquia?', content: 'A franquia é a importância que, em caso de sinistro, fica a teu cargo e cujo montante ou forma de cálculo consta das Condições Particulares.' },
				{ title: 'O que se entende por período de carência?', content: 'É o período que decorre entre o início do seguro e a data, a partir da qual, podem ser acionadas de determinadas coberturas.' },
				{ title: 'O que devo fazer em caso de sinistro?', content: 'Em caso de sinistro, antes de assumir qualquer despesa, contacta o nosso serviço de assistência, disponível 24 horas por dia, 365 dias por ano, através do número +351 214 238 416, para que te possamos indicar como proceder.' }
			],
			accordionContent3: [
				{ title: 'Como posso pedir assistência?', content: 'Caso necessites de assistência, liga +351 214 238 416. A nossa equipa está disponível para te ajudar 24 horas por dia, 365 dias por ano.' },
				{ title: 'Se tiver contratado um seguro, o que é que a assistência inclui?', content: 'Se contratares o seguro de viagem Just in Case para te proteger durante a tua viagem, ele inclui as seguintes coberturas:<br><br>• Despesas médicas, farmacêuticas e de hospitalização no estrangeiro;<br>• Transporte de urgência ilimitado;<br>• Envio de medicamentos de urgência;<br>• Regresso antecipado da pessoa segura;<br>• Procura e transporte de bagagem perdida;<br>• Perda de ligação aéreas;<br>• Informações úteis;<br>• Perda de passaporte.<br><br><a href="https://www.fidelidade.pt/justincase/Assistencia_as_Pessoas_Nivel_Extra.pdf" target="_blank">Clica aqui</a> para consultar todas as garantias incluídas na cobertura de assistência: Esta informação não dispensa a consulta da informação pré-contratual e contratual legalmente exigida.' },
				{ title: 'O que devo fazer em caso de sinistro?', content: 'Em caso de sinistro, antes de assumires qualquer despesa, contacta o nosso serviço de assistência, disponível 24 horas por dia, 365 dias por ano, através do número +351 214 238 416, para que te possamos dizer como proceder.' }
			],
			tab: 1,
			faq: 0
		}
	},
	methods: {
		clickTabs: function() {
		
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/_variables.scss';

#faq {
/* 	@media (min-width: $xlarge) {
		padding-right: 80px;
		padding-left: 80px;
	}

	@media (min-width: 1280px) {
		padding-right: 150px;
		padding-left: 150px;
	} */
}

.faqs {
	background-color: $color-gray-bg;
	margin: 50px 0px;

	.content-wrapper {
		color: $color-gray-features;
		padding-top: 35px;

		@media (min-width: $medium) {
			padding-top: 60px;
			padding-bottom: 60px;
		}

		@media (min-width: $large) {
			padding-top: 80px;
			padding-bottom: 80px;
		}

		h3 {
			font-size: 20px;
			line-height: 32px;
			letter-spacing: 0.23px;
			margin-bottom: 60px;

			// Exception
			@media (min-width: 340px) {
				font-size: 22px;
			}

			@media (min-width: $medium) {
				font-size: 36px;
				font-weight: bold;
			}
		}
	}

	.tab-wrapper {

		@media (min-width: $xlarge) {
			padding: 0 150px;
		}

		ul {
			margin-bottom: 20px;

			@media (min-width: $medium) {
				margin-bottom: 90px;
			}

			li {	
				max-width: 260px;

				@media (min-width: $medium) {
					height: 160px;
					width: 260px;
				}

				.item-wrapper {
					cursor: pointer;
					padding: 18px 0;
					width: 100%;
    				height: 100%;
					border-radius: 8px;
  					box-shadow: 0 2px 4px 0 #e7e7e7;
					background-color: rgba(255, 255, 255, 0.6);

					@media (min-width: $medium) {
						padding: 30px 0;
					}

					.image {
						width: 24px;
						height: 24px;
						margin-bottom: 18px;

						@media (min-width: $medium) {
							width: 40px;
							height: 40px;
							margin-bottom: 32px;
						}
						
						img {
							width: 100%;
							height: 100%;
						}
					}

					span {
						display: block;
						font-size: 14px;
						font-weight: 700;
						line-height: 18px;
						color: $color-gray-light;

						@media (min-width: $medium) {
							line-height: 1;
							  letter-spacing: 0.2px;
							  font-size: 24px;
						}
					}

					&.active {
						color: $color-black;
						border: 1px solid $color-main;

						image {
							color: red!important;

							img {
								fill: red!important;
							}
						}

						.tab-icon * {
							fill: $color-main;
						}

						span {
							color: $color-black;
						}
						&::after {
							background-color: $color-main;
						}
					}
				}

				&:not(:last-child) {
					//margin-right: 20px;
				}
				.tab-icon * {
					fill: $color-gray-light;
				}
				
			}
		}
	}

	.bottom-panel {
		width: 100%;
		height: 60px;
		background-color: $color-main;
		background: url('../assets/img/wave_03@2x.png');
		background-size: 100%;
		background-repeat: no-repeat;
		background-position: center bottom;

		@media (min-width: $medium) {
			height: 90px;
		}

		@media (min-width: $large) {
			height: 100px;
		}

		@media (min-width: $xxlarge) {
			height: 150px;
		}
	}
}
</style>