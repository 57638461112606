<template>
  <section class="hero" id="hero">
    <div class="hero-bg"></div>
    <div class="container">
      <div class="row">
        <div class="left-panel col-12 col-md-6">
          <div class="hero-left-container">
            <h2>Viaja seguro.<br />Nós vamos contigo,<br />Just in Case.</h2>
            <h3 class="-desktop">
              Faz o seguro da tua viagem desde 0,63€ / dia*
            </h3>
            <h3 class="-mobile">
              Faz o seguro da tua viagem<br />
              desde 0,63€ / dia*
            </h3>
            <h4>
              *
             <!--<a href="/detalhe-simulacao/europa/1/90/57.11/true"
                >Ver detalhe do exemplo de preço indicado</a
              >-->
              <router-link :to="{name:'detalhe',params: { zone: 'europa', days: 90, value:57.11, people: 1, example:true  }}">Ver detalhe do exemplo de preço indicado</router-link>
            </h4>
            <div class="apps-panel">
              <a
                v-show="isIos"
                href="https://apps.apple.com/pt/app/just-in-case/id1463794084"
              >
                <img
                  src="../assets/img/apple@2x.png"
                  alt="Descarregar na App Store"
                />
              </a>
              <a
                v-show="isAndroid"
                href="https://play.google.com/store/apps/details?id=pt.fidelidade.b2c.JustinCase&hl=pt_PT"
              >
                <img
                  src="../assets/img/android@2x.png"
                  alt="Disponível no Google Play"
                />
              </a>
            </div>
          </div>
        </div>
        <div class="right-panel col-12 col-md-6">
          <div class="hero-splash">
            <img src="../assets/img/phones@2x.png" alt="Phones" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Hero",
  computed: {
    isIos() {
      if (window.innerWidth < 768) {
        if (navigator.userAgent.toLowerCase().indexOf("iphone") > -1) {
          return 1;
        }
        return 0;
      }
      return 1;
    },
    isAndroid() {
      if (window.innerWidth < 768) {
        if (navigator.userAgent.toLowerCase().indexOf("android") > -1) {
          return 1;
        }
        return 0;
      }
      return 1;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/_variables.scss";

.hero {
  padding-top: 122px;
  margin-bottom: 22px;
  position: relative;

  @media (min-width: $xlarge) {
    .left-panel {
      padding-left: 80px;
    }

    .right-panel {
      padding-right: 80px;
    }
  }

  @media (min-width: 1280px) {
    .left-panel {
      padding-left: 150px;
    }

    .right-panel {
      padding-right: 150px;
    }
  }

  .hero-bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;

    //svg musking starts here
    background-image: url("../assets/img/hero-bg@2x.jpg");
    background-size: cover;
    mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1280 590' preserveAspectRatio='xMaxYMax slice'%3E%3Cpath d='M0 0h1280v588.87c-170.797-63.25-314.164-104.252-430.1-123.006-173.903-28.132-266.438 108.06-476.847 123.005C162.645 603.815 0 465.671 0 465.671S.337 0 0 0z' fill='%237196D4' fill-rule='nonzero'/%3E%3C/svg%3E");
    mask-repeat: no-repeat;
    mask-size: cover;
    mask-position: bottom;
    //svg musking ends here

    &::before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: linear-gradient(
        rgba(113, 150, 212, 0.5),
        rgba(113, 150, 212, 0)
      );
      z-index: -1;
    }

    //svg musking fallback for IE
    @media screen and (min-width: 0\0) and (min-resolution: +72dpi) {
      width: 100vw;
      height: 100vh;
      background-image: url("../assets/img/hero-bg@IEnew.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: bottom;
      z-index: -1;
    }
  }

  .left-panel {
    text-align: center;
    padding-bottom: 3.5rem;

    @media (min-width: 768px) {
      text-align: left;
      margin: auto 0;
    }
    .hero-left-container {
      @media (min-width: 768px) {
        max-width: 510px;
      }
      h2 {
        @media (min-width: $medium) {
          font-size: 52px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.15;
          letter-spacing: 0.2px;
        }
      }

      h3 {
        font-size: 20px;
        line-height: 28px;
        margin-top: 4.5rem;
        color: $color-white;

        @media (min-width: 980px) {
          font-size: 36px;
          text-shadow: 0 2px 10px #6b7688;
          font-weight: bold;
          line-height: 1.33;
        }
        &.-mobile {
          @media (min-width: 767px) {
            display: none;
          }
        }
        &.-desktop {
          display: none;
          @media (min-width: 767px) {
            display: block;
          }
        }
      }
      h4 {
        color: white;
        padding-top: 5px;
      }
      a {
        color: white;
        text-decoration: underline;
      }
    }
  }

  .right-panel {
    padding-bottom: 1rem;

    .hero-splash {
      position: relative;
      margin-left: auto;
      max-width: 440px;

      img {
        width: 100%;
      }
    }
  }
}
</style>
