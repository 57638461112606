<template>
	<section class="awards" id="awards">
		<div class="waveawards"><img src="../assets/img/divider_awards.svg"></div>
		<div class="container">
			<div class="row">
				<div class="col-12 text-center">
					<div class="stamps">
						<div class="stamps-title"><span>Prémios</span></div>
						<div class="content">
							<img src="../assets/img/group-18-2-x@2x.png" alt="stamp">
							<img src="../assets/img/group-17-2-x@2x.png" alt="stamp">
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: 'Awards'
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/_variables.scss';

.awards {
	background-color: $color-gray-bg;
	padding-top: 50px;
	padding-bottom: 80px;
	position: relative;

	.waveawards {
		position: absolute;
		top: 0px;
		width: 100%;
		img {
			object-fit: contain;
    		width: 100%;
		}
	}

	@media screen and (min-width: $medium) and (max-width: 1440px) {
		padding-bottom: 120px;
		padding-top: 100px;
	}

	@media (min-width: 1440px) {
		padding-top: 150px;
	}

	.stamps {
		text-align: center;

		.stamps-title {
			margin-bottom: 40px;

			@media (min-width: $medium) {
				margin-bottom: 60px;
			}

			@media (min-width: $large) {
				margin-bottom: 80px;
			}

			span {
				color: $color-black;
				font-size: 22px;
				font-weight: 600;

				@media (min-width: $medium) {
					font-size: 30px;
					font-weight: 700;
				}

				@media (min-width: $large) {
					font-size: 36px;
					font-weight: 700;
				}
			}
		}

		img {
			height: 100px;
			margin: 0px 30px;

			@media (min-width: $medium) {
				height: 150px;
				margin: 0px 40px;
			}
		}
	}
}
</style>