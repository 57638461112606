<template>
  <div class="container-fluid p-0" id="detalhe-container">
    <div class="row no-gutters">
      <div class="col-12">
        <div
          class="detalhe-header d-flex justify-content-center align-items-center"
        >
          <div class="close-button" v-on:click="goHome">
            <i slot="extra" class="icon mdi mdi-close"></i>
          </div>
          <!-- 
          <div class="title-container">-->
          <div class="title">
            <span v-if="isExample"
              >DETALHE DO EXEMPLO DE PREÇO INDICADO
              <div class="bottom-text">
                <!-- <p>O prémio total apresentado é válido até 31-12-2020.</p> -->
              </div>
            </span>
            <span v-else>Detalhe de simulação</span>
          </div>
          <!--
            <span v-show="isExample"
              >O prémio total apresentado é válido até 31-12-2020.</span
            >
          </div> -->
        </div>
      </div>
    </div>
    <div class="row content-container mx-auto">
      <div class="col-12">
        <div class="simulation-data">
          <span class="data-title">Destino da viagem</span>
          <span class="data-values">{{ zone }}</span>
          <span class="data-title">Duração</span>
          <span class="data-values"
            >{{ days }} <span class="val" v-if="days > 1"> dias</span
            ><span class="val" v-else>dia</span></span
          >
          <span class="data-title">Pessoas Seguras</span>
          <span class="data-values">{{ people }}</span>
        </div>
        <div class="detalhe-coberturas">
          <table>
            <tr>
              <th>Coberturas</th>
              <th>Capitais</th>
            </tr>
            <tr>
              <td>Invalidez permanente por acidente</td>
              <td>25.000 €</td>
            </tr>
            <tr>
              <td>Despesas de funeral por acidente</td>
              <td>1.250 €</td>
            </tr>
            <tr>
              <td>Despesas de tratamento em portugal por acidente</td>
              <td>2.500 €</td>
            </tr>
            <tr>
              <td>Bagagem não acompanhada</td>
              <td>500 €</td>
            </tr>
            <tr>
              <td>
                Incapacidade temporária por internamento hospitalar
                (indemnização diária)
              </td>
              <td>25 €</td>
            </tr>
            <tr>
              <td class="no-border">Assistência às pessoas</td>
              <td class="no-border">Nível Estrangeiro extra</td>
            </tr>
          </table>
        </div>
        <div class="simulation-value" v-if="!isExample">
          <div class="value-area mx-auto">
            <p class="value-text">Seguro de viagem</p>
            <p class="value-text">prémio total {{ value }}&euro;</p>
            <p>
              para a viagem com a duração e o número de pessoas acima indicado
            </p>
          </div>
          <div class="bottom-text">
            <p>
              O prémio total apresentado é válido até às 23:59 do dia
              {{ nowString }}.
            </p>
          </div>
        </div>
        <div v-else class="simulation-value">
          <div class="value-area mx-auto">
            <p class="value-text">Valor a pagar {{ value }}&euro;</p>
            <p>
              por pessoa segura para a viagem considerando o destino e duração
              acima indicado e bem assim o seguro com as coberturas atras
              mencionadas
            </p>
          </div>
          <div class="bottom-text">
            <p>O prémio total apresentado é válido até 31-12-2020.</p>
            <br />
            <p>
              Este exemplo é meramente indicativo.<br />Descarrega a app, faz a
              tua simulação e vê o preço para a tua viagem!
            </p>
          </div>
        </div>
        <div class="info">
          <p class="info-title">Informação Importante:</p>
          <p v-if="isExample">
            O valor do presente exemplo assenta num conjunto de pressupostos,
            como sejam os dados assumidos e a inexistência de fatores de
            agravamento de risco.<br />
            Para os dados considerados, apurou-se o prémio acima, que inclui
            cargas fiscais e parafiscais em vigor nesta data. Qualquer alteração
            legal posterior às cargas fiscais ou parafiscais aplicáveis, terá
            que ser considerada pelo Segurador aquando da emissão do contrato.
            As coberturas indicadas estão sujeitas aos limites de indemnização,
            franquias, exclusões e períodos de carência estabelecidos nas
            Condições Contratuais aplicáveis.<br />
            Este exemplo é válido até à data acima indicada, sem prejuízo da
            possibilidade, aquando da emissão do contrato, de alteração do
            prémio apurado caso ocorra uma alteração legal posterior à data da
            simulação que determine a modificação das cargas fiscais ou
            parafiscais aplicáveis.<br />
            <br />A informação constante deste simulador não dispensa a consulta
            da informação pré-contratual e contratual legalmente exigida.<br />
          </p>
          <p v-else>
            O valor do prémio apresentado nesta simulação assenta num conjunto
            de pressupostos, como sejam os dados que nos indicou e a
            inexistência de fatores de agravamento de risco, pressupostos estes
            sujeitos a confirmação por parte do Segurador.<br />
            Para os dados indicados, apurou-se o prémio acima, que inclui cargas
            fiscais e parafiscais em vigor à data da simulação. Qualquer
            alteração legal posterior às cargas fiscais ou parafiscais
            aplicáveis, terá que ser considerada pelo Segurador aquando da
            emissão do contrato. As coberturas indicadas estão sujeitas aos
            limites de indemnização, franquias, exclusões e períodos de carência
            estabelecidos nas Condições Contratuais aplicáveis.<br />
            O prémio desta simulação e a verificação dos respetivos pressupostos
            carecem de confirmação e aceitação por parte do Segurador. Esta
            simulação tem validade de 24 horas, sem prejuízo da possibilidade,
            aquando da emissão do contrato, de alteração do prémio apurado caso
            ocorra uma alteração legal posterior à data da simulação que
            determine a modificação das cargas fiscais ou parafiscais
            aplicáveis.<br />
            <br />
            A informação constante deste simulador não dispensa a consulta da
            informação pré-contratual e contratual legalmente exigida.<br />
          </p>
          <div
            class="links-area d-flex flex-column justify-content-center align-items-start"
          >
            <div>
              <img src="../assets/img/icon-info.png" alt="Icon info" /><a
                href="https://www.fidelidade.pt/justincase/Informacoes_Pre-Contratuais.pdf"
                >Veja aqui Informações Pré-Contratuais (inclui Documento de
                Informação sobre o Produto de Seguros)</a
              ><br />
            </div>
            <div>
              <img src="../assets/img/icon-info.png" alt="Icon info" /><a
                href="https://www.fidelidade.pt/justincase/Condicoes_Gerais_e_Especiais.pdf"
                >Veja aqui Condições Gerais e Especiais</a
              ><br />
            </div>
          </div>
        </div>
        <div class="detalhe-footer">
          <p v-show="!isExample">
            Para questões adicionais contacte-nos através do 217 948 780 ou do
            e-mail
            <a href="mailto:apoiocliente@fidelidade.pt"
              >apoiocliente@fidelidade.pt</a
            ><br />
            <br />
          </p>
          <p>Um seguro da Fidelidade – Companhia de Seguros, S.A.</p>
        </div>
        <!--
            ponto 8
            nao sei se querem que apague mesmo o rodapé ou simplesmente deixe o show a false por enquanto -->
        <div class="rodape" v-show="false">
          <div class="rodape-left">
            <img
              src="../assets/img/footer_fidelidade.jpg"
              alt="footer fidelidade"
            />
            <p class="logo-text">
              808 29 39 49 <br />
              fidelidade.pt
            </p>
          </div>
          <div class="rodape-middle">
            <p class="info-title">Fidelidade - Companhia de Seguros, S.A</p>
            <p>
              NIPC e Matrícula 500 918 880, na CRC Lisboa - Sede: Largo do
              Calhariz, 30 1249-001 Lisboa - Portugal - Capital Social € 457 380
              000 - www.fidelidade.pt
            </p>
          </div>
          <div class="rodape-right">
            <p class="info-title">Linha de Apoio ao Cliente</p>
            <p>
              T. 808 29 39 49 - E. apoiocliente@fidelidae.pt - Atendimento
              telefónico personalizado nos dias úteis das 8h ás 23h e Sábados
              das 8h às 20h.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let now = new Date();
let formatedDate = [
  ("0" + (parseInt(now.getDate()) + 1).toString()).slice(-2),
  ("0" + (now.getMonth() + 1)).slice(-2),
  now.getFullYear(),
].join("-");

export default {
  name: "App",
  data() {
    return {
      zone: "europa",
      people: 0,
      days: 0,
      value: 0,
      nowString: formatedDate,
      example: "",
    };
  },
  methods: {
    goHome: function () {
      this.$router.push({ path: "/" });
    },
  },
  mounted() {
    if (this.$route.params) {
      this.zone =
        this.$route.params["zone"] === "europa"
          ? "Europa e Países da Bacia do Mediterrâneo"
          : "Resto do mundo";
      this.people = this.$route.params["people"];
      this.days = this.$route.params["days"];
      this.value = this.$route.params["value"];
      this.example = this.$route.params["example"];
    }
  },
  computed: {
    isExample() {
      if (this.example === "true") {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/_variables.scss";

#detalhe-container {
  .detalhe-header {
    height: 65px;
    text-align: center;
    -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);

    .title {
      span {
        font-size: 18px;
        line-height: 22px;
        font-weight: 600;

        .bottom-text {
          p {
            font-size: 12px;
            line-height: 16px;
            color: $color-black;
          }
        }
      }
    }

    //ponto 6

    @media screen and (max-width: 500px) {
      .title {
        span {
          margin-left: 30px;
        }
      }
    }

    .close-button {
      position: absolute;
      top: 18px;
      left: 10px;
      font-size: 30px;
      color: $color-blue-button;
    }
  }

  @media screen and (max-width: 400px) {
    .detalhe-header {
      height: 100px;
    }
    .close-button {
      margin-top: 15px;
    }
  }

  .content-container {
    width: 100%;

    @media (min-width: $small) {
      width: 75%;
    }

    @media (min-width: $medium) {
      width: 70%;
    }

    @media (min-width: $large) {
      width: 60%;
    }

    @media (min-width: $xlarge) {
      width: 48%;
    }

    .simulation-data {
      margin-top: 25px;
      padding: 0 15px;

      .data-title {
        display: block;
        color: $color-gray;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.12px;
      }

      .data-values {
        display: block;
        color: $color-black;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.12px;
        margin-bottom: 20px;
      }
      .val {
        color: $color-black;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.12px;
      }
    }

    .detalhe-coberturas {
      text-transform: uppercase;
      table {
        width: 100%;

        tr {
          height: 40px;
        }

        tr:first-child {
          height: 45px;
          background-color: #f8f8f8;

          th {
            vertical-align: middle;
            text-transform: uppercase;
            font-weight: 600;
            color: $color-black;
          }
        }

        td {
          vertical-align: middle;
          font-size: 12px;

          &.no-border::before {
            content: none !important;
          }
        }

        td:first-child,
        th:first-child {
          position: relative;
          width: 70%;
          padding-left: 15px;
        }

        td:first-child {
          &::before {
            content: "";
            position: absolute;
            left: 15px;
            bottom: 0;
            height: 2px;
            width: 100%;
            border-bottom: 2px solid #dee2e6;
          }
        }

        td:last-child,
        th:last-child {
          position: relative;
          width: 30%;
          padding-right: 15px;
          text-align: right;
        }

        td:last-child {
          &::before {
            content: "";
            position: absolute;
            right: 15px;
            bottom: 0;
            height: 2px;
            width: 100%;
            border-bottom: 2px solid #dee2e6;
          }
        }
      }
    }

    .simulation-value {
      margin: 40px 0 60px;
      padding: 40px 15px;
      border-top: 2px solid #dee2e6;
      border-bottom: 2px solid #dee2e6;
      text-align: center;

      .value-area {
        width: 75%;
        padding: 15px;
        border-radius: 10px;

        p {
          font-size: 12px;
          line-height: 16px;
          color: $color-gray;

          &.value-text {
            font-size: 14px;
            font-weight: 600;
            line-height: 18px;
            color: $color-black;
          }
        }
      }

      .bottom-text {
        margin-top: 30px;

        p {
          font-size: 12px;
          line-height: 16px;
          color: $color-black;
        }
      }
    }

    .info {
      margin-bottom: 50px;
      padding: 0 15px;

      p {
        font-size: 12px;
        line-height: 16px;
        color: $color-gray;

        &.info-title {
          text-transform: uppercase;
          font-weight: 600;
          color: $color-black;
          margin-bottom: 5px;
        }
      }

      .links-area {
        a {
          font-size: 12px;
          margin-left: 15px;
          color: $color-main;
          text-decoration: underline;
          line-height: 25px;
        }
      }
    }

    .detalhe-footer {
      text-align: left;
      margin-bottom: 55px;

      p {
        font-size: 13px;
        line-height: 18px;

        a {
          color: $color-black;
          text-decoration: underline;
        }
      }
    }
    .rodape {
      display: flex;
      margin-bottom: 30px;
      img {
        padding-top: 5px;
        height: 40px;
        @media (max-width: $small) {
          height: 30px;
        }
      }
      > * {
        margin: 0px 5px;
        @media (max-width: $small) {
          margin: 0px 2px;
        }
      }
      .rodape-left {
        display: flex;
        p.logo-text {
          font-size: 14px;
          line-height: 14px;
          font-weight: bold;
          text-align: center;
          width: 100px;
          @media (max-width: $small) {
            font-size: 11px;
            line-height: 12px;
            width: 80px;
          }
        }
      }
      .info-title {
        font-weight: bold;
        padding-bottom: 5px;
      }
      p {
        color: #79615d;
        font-size: 10px;
        line-height: 12px;
      }
    }
  }
}
</style>