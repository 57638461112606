<template>
  <section class="benefits" id="benefits">
    <div class="container">
      <div class="one-image">
        <img
          class="visual-element"
          alt="something"
          src="../assets/img/image004.png"
        />
      </div>
      <div class="row">
        <div class="col-12 lounge-access text-center">
          <div class="checklist-background"></div>
          <div class="content-wrapper">
            <h3>Acesso ao Lounge</h3>
            <div class="small-info-wrapper mx-auto">
              <div class="small-info mx-auto">
                <span>Na subscrição do seguro</span>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="lounge-img d-md-none">
                  <img src="../assets/img/lounge-card_02-with-bg@2x.png" />
                </div>
                <div class="lounge-img d-none d-md-block">
                  <img src="../assets/img/both-cards-with-bg@2x.png" />
                </div>
              </div>
              <div class="col-12 col-md-6 text-md-left">
                <p>
                  Ao comprares o teu seguro de viagem, oferecemos-te acesso ao
                  lounge do aeroporto, caso o teu voo se atrase.<br />
                  <br />
                  Podes incluir até cinco amigos ou familiares que estejam a
                  viajar contigo.*
                </p>
                <p class="asterisk">*Brevemente disponível</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 appointment text-center text-md-left">
          <div class="content-wrapper">
            <h3>Consulta do viajante</h3>
            <div class="small-info mx-auto mx-md-0">
              <span>Na subscrição do seguro</span>
            </div>
            <div class="row">
              <div class="col-12 col-md-6 order-md-2">
                <div class="appointment-img">
                  <img src="../assets/img/medical_with_bg@2x.png" />
                </div>
              </div>
              <div class="col-12 col-md-6 order-md-1">
                <p>
                  Para que o planeamento da tua viagem seja ainda mais fácil,
                  tens acesso a uma consulta do viajante para tirares dúvidas
                  sobre os cuidados a ter antes, durante e depois da viagem.<br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Benefits",
};
</script>

<style lang="scss">
@import "@/assets/sass/_variables.scss";

.benefits {
  width: 100%;
  text-align: right;
  .one-image {
    width: 100%;
    text-align: right;
    margin-top: -80px;
    .visual-element {
      margin-bottom: 20px;
      width: 250px;
      @media (max-width: 767px) {
        width: 150px;
      }
    }
  }
  .container {
    @media (min-width: $xlarge) {
      padding-left: 80px;
      padding-right: 80px;
    }

    @media (min-width: $xlarge) {
      padding-left: 150px;
      padding-right: 150px;
    }
  }

  .content-wrapper {
    color: $color-gray-features;

    h3 {
      font-size: 20px;
      line-height: 32px;
      letter-spacing: 0.23px;

      // Exception
      @media (min-width: 340px) {
        font-size: 22px;
      }
    }

    p {
      font-weight: 200;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: 0.16px;
      margin-bottom: 30px;
      padding-bottom: 0;

      &.asterisk {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.18px;
      }
    }
  }

  .small-info {
    border-radius: 5px;
    width: 205px;
    height: 24px;
    margin-bottom: 20px;

    @media (min-width: $medium) {
      width: 244px;
      text-align: center;
    }

    span {
      text-transform: uppercase;
      font-size: 10px;
      letter-spacing: 0.08px;
      color: $color-white;

      @media (min-width: $medium) {
        font-size: 12px;
      }
    }
  }

  .lounge-access {
    margin-bottom: 50px;

    h3 {
      padding-bottom: 8px;

      @media (min-width: $medium) {
        font-size: 36px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 48px;
        letter-spacing: 0.2px;
        padding-bottom: 15px;
        padding-left: 330px;
      }
    }

    .small-info-wrapper {
      width: 205px;
      padding-bottom: 0;

      @media (min-width: $medium) {
        width: 244px;
        padding-left: 134px;
      }

      .small-info {
        background-color: $color-small-info-orange;

        span {
          display: block;
          line-height: 24px;
        }

        @media (min-width: $medium) {
          margin-left: 476px;
        }
      }
    }

    .lounge-img {
      width: 252px;
      margin: 20px auto;

      @media (min-width: $medium) {
        position: absolute;
        width: 100%;
        margin: 0;
        left: -50px;
        top: -125px;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .appointment {
    @media (min-width: $medium) {
      margin: 100px 0;
    }

    h3 {
      padding-bottom: 8px;

      @media (min-width: $medium) {
        font-size: 36px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 48px;
        letter-spacing: 0.2px;
        padding-bottom: 15px;
      }
    }

    .appointment-text {
      margin-top: 40px;
      font-size: 20px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.17px;
      color: #8b8b8b;
    }

    .small-info {
      background-color: $color-small-info-blue;

      span {
        display: block;
        line-height: 24px;
      }
    }

    .appointment-img {
      width: 252px;
      margin: 0 auto;
      padding-bottom: 8px;

      @media (min-width: $medium) {
        width: auto;
        margin: -155px auto 0 auto;
        max-width: 400px;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>