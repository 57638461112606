import Vue from "vue";

export const store = Vue.observable({
    zone: 'europa',
    people: 0,
    days: 0,
    value: '10'
});

export const mutations = {
    setValues(zone, people, days, value) {
        store.zone = zone;
        store.people = people;
        store.days = days;
        store.value = value;
    }
};