<template>
	<section class="contacts" id="contactos">
		<div class="container">
			<div class="row">
				<div class="col-12 text-center">
					<div class="row">
						<div class="col-12 col-md-6 wrap-left">
							<h3>Contactos</h3>
							<p>Tens questões em que possamos ajudar?<br>Liga-nos!</p>
						</div>
						<div class="col-12 col-md-6 content-wrapper">
							<div v-on:click="number" class="phone-number d-flex">
								<div class="image"><img src="../assets/img/icon-phone.svg"></div>
									<a href="tel:+351217948780">217 948 780</a>
							</div>
							<span class="mx-auto mx-0">Dias úteis das 9h às 20h</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: 'Contacts',
	methods: {
		number: function() {
			dataLayer.push({'event': 'eventPage', 'eventAction': 'Click', 'eventCategory': 'Just in Case - Contactos', 'eventLabel': 'Número de Telefone'});
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/_variables.scss';

.contacts {
	background-color: $color-white;
	padding-bottom: 30px;
	margin-top: 30px;

	@media (min-width: $medium) {
		display: flex;
		text-align: left!important;
		margin-top: 60px;
		margin-bottom: 100px;
	}

	@media (min-width: $xlarge) {
		.wrap-left {
			padding-left: 80px;
		}

		.content-wrapper {
			padding-right: 80px;
		}
	}

	@media (min-width: 1280px) {
		.wrap-left {
			padding-left: 150px;
		}

		.content-wrapper {
			padding-right: 150px;
		}
	}

	.wrap-left {
		
		h3 {
			font-size: 20px;
			line-height: 32px;
			letter-spacing: 0.23px;

			@media (min-width: $medium) {
				text-align: left;
				font-size: 30px;
				font-weight: bold;
			}

			@media (min-width: $large) {
				font-size: 36px;
				font-weight: bold;
			}
		}

		p {
			font-size: 18px;
			font-weight: 200;
			line-height: 26px;
			letter-spacing: 0.16px;
			color: $color-black;
			margin-top: 30px;

			@media (min-width: $medium) {
				font-weight: 300;
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				letter-spacing: 0.17px;
				color: #171717;
				text-align: left;
			}

			@media (min-width: $large) {
				margin-top: 50px;
				font-size: 20px;
			}
		}
	}

	.content-wrapper {
		margin-top: 30px;

		@media (min-width: $medium) {
			margin-top: 0px;
			padding-bottom: 31px;
		}

		p {
			font-size: 18px;
			font-weight: 200;
			line-height: 26px;
			letter-spacing: 0.16px;
			color: $color-black;
		}

		.phone-number {
			justify-content: center;

			@media (min-width: $medium) {
				justify-content: start;
				padding-bottom: 30px;
			}

			.image {
				margin-right: 16px;
				width: 28px;
				height: 28px;

				@media (min-width: $large) {
					width: 56px;
					height: 56px;
				}

				img {
					height: 100%;
				}
			}
	
			a {
				display: block;
				font-size: 28px;
				font-weight: 700;
				color: $color-main;
				text-decoration: none;

				@media (min-width: $medium) {
					font-size: 36px;
					font-weight: 700;
				}

				@media (min-width: $large) {
					font-size: 56px;
					font-weight: 700;
				}
			}
		}

		span {
			display: block;
			width: 190px;
			font-size: 16px;
			font-weight: 200;
			line-height: 20px;
			color: $color-gray-features;

			@media (min-width: $medium) {
				width: auto;
				padding-left: 35px;
				text-align: left;
			}
		}
	}

	.stamps {
		text-align: center;
		img {
			height: 190px;
			width: 190px;
			margin: 0px 30px;
		}
	}
}
</style>