<template>
	<div>
		<div>
			<Header />
			<Hero />
			<template v-if="featuresFirst">
				<div v-waypoint="{ active: true, callback: features, options: intersectionOptions }">
					<Features />
				</div>
				<div v-waypoint="{ active: true, callback: seguro, options: intersectionOptions }">
					<Seguro />
				</div>
			</template>
			<template v-else>
				<div v-waypoint="{ active: true, callback: seguro, options: intersectionOptions }">
					<Seguro />
				</div>
				<div v-waypoint="{ active: true, callback: features, options: intersectionOptions }">
					<Features />
				</div>
			</template>
			<div v-waypoint="{ active: true, callback: benefits, options: intersectionOptions }">
				<Benefits />
			</div>
			<div v-waypoint="{ active: true, callback: faq, options: { threshold: [0.2] } }">
				<Faq />
			</div>
			<div v-waypoint="{ active: true, callback: contacts, options: intersectionOptions }">
				<Contacts />
			</div>
			<div v-waypoint="{ active: true, callback: contacts, options: { threshold: [0.3] } }">
				<Awards />
			</div>
		</div>
		<Footer />
	</div>
</template>

<script>
import Header from '@/components/Header.vue'
import Hero from '@/components/Hero.vue'
import Features from '@/components/Features.vue'
import Benefits from '@/components/Benefits.vue'
import Seguro from '@/components/Seguro.vue'
import Faq from '@/components/Faq.vue'
import Contacts from '@/components/Contacts.vue'
import Awards from '@/components/Awards.vue'
import Footer from '@/components/Footer.vue'


export default {
	name: 'App',
	components: {
		Header,
		Hero,
		Features,
		Benefits,
		Seguro,
		Faq,
		Contacts,
		Awards,
		Footer
	},
	data() {
		return {
			featuresFirst: false,
			intersectionOptions: {
				root: null,
				rootMargin: '0px 0px 0px 0px',
				threshold: [0, 0] // [0.25, 0.75] if you want a 25% offset!
			}
		}
	},
	mounted() {
		window.dataLayer = window.dataLayer || []
		// If there's a call to /features in the address bar on the browser
		// we must render the page differently. "Features" come first and only the "Seguros"
		if(this.$route.path == '/features/') {
			this.featuresFirst = true
		}
		else {
			this.featuresFirst = false
		}
	},
	destroyed() {
	},
	methods: {
		features ({ going }) {
			if (going === this.$waypointMap.GOING_IN) {
					window.dataLayer.push({
					'event': 'Pageview',
					'pagePath': '#features',
					'pageTitle': 'Features'
				});
			}
		},
		benefits ({ going }) {
			if (going === this.$waypointMap.GOING_IN) {
					window.dataLayer.push({
					'event': 'Pageview',
					'pagePath': '#benefits',
					'pageTitle': 'Benefits'
				});
			}
		},
		seguro ({ going }) {
			if (going === this.$waypointMap.GOING_IN) {
				window.dataLayer.push({
					'event': 'Pageview',
					'pagePath': '#seguro',
					'pageTitle': 'Seguro'
				});
			}
		},
		faq ({ going }) {
			if (going === this.$waypointMap.GOING_IN) {
				window.dataLayer.push({
					'event': 'Pageview',
					'pagePath': '#faq',
					'pageTitle': 'FAQ'
				});
			}
		},
		contacts ({ going }) {
			if (going === this.$waypointMap.GOING_IN) {
				window.dataLayer.push({
					'event': 'Pageview',
					'pagePath': '#contacts',
					'pageTitle': 'Contactos'
				});
			}
		},
	}
}
</script>

<style lang="scss">
@import '@/assets/sass/_variables.scss';

.tooltip {
	display: block !important;
	z-index: 10000;

	&.europe {
		.tooltip-inner {
			background: $color-footer-bg;
			color: $color-font-tooltip;
			border-radius: 10px;
			border: 2px solid $color-black;
			padding: 10px 15px;
			width: 330px;
			font-size: 14px;
			line-height: 20px;
			letter-spacing: 0.18px;
			max-width: 330px;
			text-align: left;
		}
	}

	.tooltip-inner {
		background: $color-footer-bg;
		color: $color-font-tooltip;
		border-radius: 10px;
		border: 2px solid $color-black;
		padding: 10px 15px;
		width: 300px;
		font-size: 14px;
		line-height: 20px;
		letter-spacing: 0.18px;
		max-width: 226px;
		text-align: left;
	}

	.tooltip-arrow {
		width: 0;
		height: 0;
		border-style: solid;
		position: absolute;
		margin: 5px;
		border-color: $color-black;
		z-index: 1;
	}

	&[x-placement^="top"] {
		margin-bottom: 15px;

		.tooltip-arrow {
			border-width: 15px 15px 0 15px;
			border-color: $color-footer-bg;
			border-left-color: transparent !important;
			border-right-color: transparent !important;
			border-bottom-color: transparent !important;
			bottom: -13px;
			left: calc(50% - 5px);
			margin-top: 0;
			margin-bottom: 0;
		}
	}

	&[x-placement^="bottom"] {
		margin-top: 5px;

		.tooltip-arrow {
			border-width: 0 5px 5px 5px;
			border-left-color: transparent !important;
			border-right-color: transparent !important;
			border-top-color: transparent !important;
			top: -5px;
			left: calc(50% - 5px);
			margin-top: 0;
			margin-bottom: 0;
		}
	}

	&[x-placement^="right"] {
		margin-left: 5px;

		.tooltip-arrow {
			border-width: 5px 5px 5px 0;
			border-left-color: transparent !important;
			border-top-color: transparent !important;
			border-bottom-color: transparent !important;
			left: -5px;
			top: calc(50% - 5px);
			margin-left: 0;
			margin-right: 0;
		}
	}

	&[x-placement^="left"] {
		margin-right: 5px;

		.tooltip-arrow {
			border-width: 5px 0 5px 5px;
			border-top-color: transparent !important;
			border-right-color: transparent !important;
			border-bottom-color: transparent !important;
			right: -5px;
			top: calc(50% - 5px);
			margin-left: 0;
			margin-right: 0;
		}
	}

	&[aria-hidden='true'] {
		visibility: hidden;
		opacity: 0;
		transition: opacity .15s, visibility .15s;
	}

	&[aria-hidden='false'] {
		visibility: visible;
		opacity: 1;
		transition: opacity .15s;
	}
}
</style>